import Vue from "vue"

import options from "@/data/staff/assistant"
let technic_options = Vue.prototype.copyObject(options)
technic_options.model = 'technic'
technic_options.fields.forEach(item => {
    if(['rate_per_day', 'report_days_limit'].includes(item.id)) { delete item.form }
})
technic_options.actionscomponent = 'StaffDatatableTechnicActions'
export default technic_options
