export default {
    accountingmonth: "Бухгалтерський місяць",
    accrual: "Нарахування",
    accruals: "Нарахування",
    AQSI_account: "AQSI рахунок",
    balance_end: "Баланс на кінець періоду",
    balance_start: "Баланс на початок періоду",
    bonus_movement: "Рух бонусів",
    cash_movements: "Рух ДС",
    cash_received: "Отримано ДС",
    closed: "Закрито",
    communal: "Комунальні платежі",
    deduction: "Утримання",
    deposit_account: "Депозитний рахунок",
    deposit_accounts: "Депозитні рахунки",
    deposit_balance: "Залишок депозиту",
    deposit_balance_end: "Залишок депозиту на кінець періоду",
    deposit_balance_start: "Залишок депозиту на початок періоду",
    deposit_movement: "Рухи за депозитом",
    employee: "Співробітник",
    expense: "Витрата",
    left_to_pay: "Залишилося виплатити",
    manual_adjustments: "Ручні коригування",
    materials_expense: "Витратні матеріали",
    open: "Відкрити",
    other_expense: "Інші витрати",
    other_income: "Інші доходи",
    pay_out: "Виплатити",
    payment_comment: "Коментар до оплати",
    payment_sum: "Сума для виплати",
    profit: "Дохід",
    recalculate: "Перерахувати",
    rent: "Оренда",
    salaries: "Зарплати",
    unclosed: "Не закрите",
}
