<template>
  <div>
    <label class="d-block form-label">{{ $t(label) }}</label>
    <div class="form-value d-inline-block">{{ title ? title : '-' }}</div>
    <slot/>
  </div>
</template>

<script>
export default {
  name: "PatientCol",
  props: {
    title: {},
    label: String
  }
}
</script>

<style scoped lang="scss">
  .form-label {
    font-size: 14px;
    margin-bottom: 0;
  }
  .form-value {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
    .form-label {
      font-size: 11px;
    }
  }
</style>
