import Vue from "vue"

import options from "@/data/staff/doctor"
import { doctorAdapter } from "@/adapters/staff/doctor"
import store from "@/store"

export const superadmin_doctor_options = () => {
    let administrator_options = Vue.prototype.copyObject(options)
    administrator_options.prepareParams = ['id', 'clinic_id']
    administrator_options.adapter = doctorAdapter
    administrator_options.fields.forEach(item => {
        if(['full_name'].includes(item.id)) {
            item.tdcomponent = 'StaffDatatableTDStaffName'
        }
    })
    administrator_options.fields.splice((administrator_options.fields.length - 1), 0 , {
        id: 'branch_id',
        title: 'branch',
        table: false,
        form: {
            type: "select",
            select_type: "model",
            select_data: {
                "title": "title"
            },
            required: true,
            classes: 'col-md-4',
        },
    })
    administrator_options.fields.splice((administrator_options.fields.length - 1), 0 , {
        id: 'blocked',
        title: 'status',
        table: true,
        sortable: true,
        tdcomponent: "SuperAdminDatatableTDUserBlocked"
    })
    administrator_options.actionscomponent = 'SuperAdminUsersActions'
    if(store.state.auth.user?.role === 'tech_specialist') {
        administrator_options.actions = ['excel']
    }
    return administrator_options
}
