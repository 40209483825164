import { superadmin_administrator_options } from "@/data/superadmin/administrator"
import { doctorAdapter } from "@/adapters/staff/doctor"
import store from "@/store"
import { copyObject } from "@/extensions/prototypes/prototypes"

export const superadmin_superadministrator_options = () => {
	const administrator_options = copyObject(superadmin_administrator_options())
	administrator_options.model = 'superadministrator'
	administrator_options.adapter = doctorAdapter
	administrator_options.actionscomponent = 'SuperAdminUsersActions'
	if(store.state.auth.user?.role === 'tech_specialist') {
		administrator_options.actions = ['excel']
	}
	return administrator_options
}
