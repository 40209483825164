export default {
    "-": "-",
    actions: "Дії",
    actions_with_selected: "Дії з вибраними",
    active: "Активний",
    add: "Додати",
    add_attachment: "Додати додаток",
    add_condition: "Додати умову",
    all: "Все",
    app_is_in_maintenance_mode: "У додатку ведуться технічні роботи. Ми обов'язково скоро повернемось",
    are_you_sure_you_want_to_delete: "Ви впевнені, що хочете видалити матеріал?",
    are_you_sure_you_want_to_logout: "Ви впевнені, що хочете вийти?",
    authorization: "Авторизація",
    authorize_in_system: "Авторизуйтеся в системі",
    balance: "Депозит",
    cancel: "Відміна",
    change: "Змінити",
    change_password: "Змінити пароль",
    comment: "Коментар",
    confirm_deletion: "Підтвердити видалення?",
    contacts: "Контакти",
    contains: "Містить",
    continue: "Продовжити",
    currency: "Валюта",
    delete: "Видалити",
    does_not_equal: "Не дорівнює",
    done: "Готово",
    down: "Униз",
    edit: "Редагувати",
    email: "Email",
    english: "Англійська",
    error: "Помилка",
    error_message: "Щось пішло не так, операція не була завершена успішно",
    equals: "Дорівнює",
    excel: "Excel",
    file: "Файл",
    file_does_not_exist: "Файл не знайдено",
    file_size_is_too_big: "Розмір файла дуже великий",
    filled: "Заповнене",
    filter: "Фільтр",
    finish: "Завершити",
    forgot_password: "Забули пароль?",
    hide: "Сховати",
    home: "Головна",
    image: "Зображення",
    import: "Iмпорт",
    input_field: "Поле для введення тексту",
    KZT: "KZT",
    language: "Мова",
    less: "Менше за",
    load_more: "Завантажити ще",
    login: "Логін",
    login_or_pass_incorrect: "Логін і / або пароль неправильні",
    logout: "Вийти",
    main: "Головна",
    message: "Повідомлення",
    module_unavailable: "Модуль недоступний",
    months: "Місяців",
    more: "Більш за",
    name: "Ім'я",
    no: "Ні",
    no_data_available: "Даних поки що немає",
    nonactive: "Неактивний",
    not_enough_rights_for_this_operation: "Недостатньо прав для цієї операції",
    nothing_found: "Нічего не знайдено",
    old_password: "Старий пароль",
    on_page: "На сторінці",
    page_not_found: "Сторінка не знайдена",
    password: "Пароль",
    password_changed: "Пароль змінений",
    password_confirm: "Підтвердження пароля",
    passwords_do_not_match: "Паролі не співпадають",
    patient_search: "Пошук пацієнта",
    payment_expires_at: "Оплата закінчується",
    phone: "Телефон",
    photo: "Фото",
    price: "Ціна",
    print_noun: "Друк",
    program: "Програма",
    profile: "Профіль",
    registration: "Реєстрація",
    refresh: "Оновити",
    requirements: "Вимоги",
    reset: "Скидання",
    reset_password: "Скидання пароля",
    reset_success: "Листа для скидання відправлено на ваш E-mail",
    role: "Роль",
    russian: "Російська",
    save: "Зберегти",
    search: "Пошук",
    select: "Вибрати",
    sent: "Відправлено",
    settings: "Налаштування",
    signin: "Увійти",
    show: "Показати",
    slogan: "Хмарне рішення для керування сучасною стоматологією",
    start: "Почати",
    status: "Статус",
    subscription_sum: "Сума за передплату",
    success_message: "Операція пройшла успішно",
    sum: "Сума",
    table_search: "Пошук по таблиці",
    tech_specialist: "Технічний спеціаліст",
    text: "Текст",
    this_module_is_not_in_tariff: "Цей модуль не входить в тариф Free, вам потрібно підключити Standard або Premium, і ви отримаєте доступ до великої кількості функціоналу програми Dentist Plus.",
    title: "Назва",
    today: "Сьогодні",
    total: "Усього",
    translation: "Переклад",
    unknown: "Невідомо",
    upload: "Завантажити",
    yes: "Так",
}
