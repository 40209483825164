export default {
    f37u78: "Form 037/Y-88",
    f43kaz: "Form 043/KAZ",
    f43rus: "Form 043/RF",
    f43ukr: "Form 043/Y",
    f58kaz: "Form 058/KAZ",
    accept_another_payment: "Accept another payment",
    activity: "Activity",
    add_complex: "Add complex",
    add_diagnosis: "Add diagnosis",
    add_question: "Add question",
    add_service: "Add service",
    add_tooth_jaw: "Add tooth / jaw",
    add_visit: "Add an appointment",
    added_by: "Added by",
    additional_discount: "Additional Discount",
    age: "Age",
    agreement: "Agreement",
    akt_of_services_for_period: "Act of services for period",
    akt_print: "Act Printing",
    allergic_reactions: "Allergic Reactions",
    allergy_diagnosis: "Allergological diagnosis",
    anamnesis: "Dental history",
    applied: "Applied",
    applied_complex: "Applied complex",
    apply_to_teeth: "Apply to teeth",
    apply_to_whole_tooth: "Apply to the whole tooth",
    apply_treatment_template: "Apply treatment pattern",
    are_you_sure_to_set_plan: "Are you sure you want to link the treatment card to the plan - the completed fields of the card can be overwritten",
    are_you_sure_you_want_to_delete_document: "Are you sure you want to delete document?",
    are_you_sure_you_want_to_delete_payment: "Are you sure you want to delete payment?",
    are_you_sure_you_want_to_delete_scan: "Are you sure you want to delete scan?",
    are_you_sure_you_want_to_delete_status: "Are you sure you want to delete status?",
    are_you_sure_you_want_to_delete_transaction: "Are you sure you want to delete transaction?",
    are_you_sure_you_want_to_delete_user: "Are you sure you want to delete user?",
    are_you_sure_you_want_to_delete_users: "Are you sure you want to delete users?",
    assistant: "Assistant",
    attach_new_user: "Attach a new user",
    author: "Author",
    available: "Available",
    baby_teeth: "Baby's teeth",
    balance_refill: "Deposit refill",
    balance_writeoff: "Deposit withdrawal",
    birthdays: "Birthdays",
    bite: "Occlusion",
    bottom_baby_teeth: "Lower baby's teeth",
    bottom_jaw: "Lower jaw",
    bottom_molars: "Lower molar teeth",
    by_doctors: "By doctors",
    by_complexes: "By complexes",
    call: "Call",
    came: "Came",
    came_from: "Came from",
    can_be_paid_with_bonuses: "Can be paid with bonuses",
    cancel_initiator: "Cancel initiator",
    cancelled: "Cancelled",
    card: "Card",
    card_number: "Card number",
    card_transfer: "Card transfer",
    cash: "Cash",
    cashless: "Cashless",
    choose_document_type_and_its_parameters: "Choose document type and its parameters",
    choose_patient: "Choose patient",
    choose_treatment_template: "Choose treatment pattern",
    client: "Patient",
    client_document: "Patient's document",
    client_id: "Пациент",
    client_note: "Note",
    client_service: "Customer service",
    code: "Code",
    color: "Color",
    combine_user_note: "By selecting a patient and clicking \"combine\", you will transfer all the data on visits, payments, diagnoses, etc. to the card of the selected patient. The current patient's card will be deleted. The procedure cannot be canceled.",
    comment_to_phone: "Comment on the phone",
    comment_to_phone_2: "Comment on the additional phone",
    complaints: "Complaints",
    complex: "Complex",
    complexes: "Complexes",
    condition: "Condition",
    confirm_tag_deletion: "Confirm tag deletion?",
    confirmed: "Confirmed",
    confirmed_male: "Approved",
    contract: "Contract",
    contract_agreement: "Concent Agreement",
    contract_general: "General Agreement",
    contract_print: "Print Agreement",
    contract_services: "Service delivery Agreement",
    consultation: "Advice",
    create_diagnosis: "Create diagnosis",
    creation_date: "Date of creation",
    date: "Date",
    date_add: "Created",
    date_from: "Date from",
    date_of_birth: "Date of birth",
    date_to: "Date to",
    dates_not_selected: "Dates are not selected",
    debt: "Debt",
    debt_cancellation: "Debt cancellation",
    delete_complex: "Delete complex",
    delete_visit: "Delete an appointment",
    department_code: "Department code",
    deposit: "Deposit",
    description: "Description",
    desired_date_from: "Desired date from",
    desired_date_to: "Desired date to",
    desired_time_from: "Desired time from",
    desired_time_to: "Desired time to",
    detailed_information: "Detailed information",
    diagnoses: "Diagnoses",
    diagnosis: "Diagnosis",
    did_not_come: "Did not come",
    discount: "Discount",
    disease_anamnez: "Disease anamnesis (current disease development)",
    disease_development: "Current disease development",
    disease_history: "Medical history",
    diseases_passed_and_current: "Previous and coexisting diseases",
    doctor: "Doctor",
    document: "Document",
    document_number: "Document number",
    document_type: "Document type",
    drag_file_here_or_select: "Drag the file here or select",
    enter_the_title: "Enter the title",
    epikriz: "Epicrisis",
    examination_plan: "Examination plan",
    f043: "F 043",
    family_account: "Family account",
    female: "Female",
    female_short: "Fem",
    fill_anamnez_from_previous_visits: "Fill anamnesis from previous visits",
    fill_balance: "Refill deposit",
    filling: "Refill",
    finish_visit: "Finish visit",
    fio: "full name",
    first_visit: "Initial consultation",
    fiscal_cheque: "Fiscal receipt",
    for_female_patients: "For female patients",
    formula: "Formula",
    foto_link_note: "Apart from uploading photo, you can put a publicly available link to image",
    from_account: "From account",
    full_card: "Full card",
    gender: "Gender",
    general_info: "General info",
    hygiene_index: "Hygiene index",
    iin: "IIN",
    in_chair: "In the chair",
    in_process: "In process",
    include_in_f043: "Include form F 043",
    inspection: "Simple examination",
    insurance: "Insurance",
    invoice_print: "Print Invoice",
    importance: "Importance",
    important: "Important",
    inn: "TIN",
    is_deleted: "Deleted",
    issue_date: "Issue date",
    issued_by: "Issued by",
    jaws: "Jaws",
    kpu_index: "KPU index",
    last_visit: "Last visit",
    left_due: "Leftover",
    life_anamnez: "Life anamnesis",
    link: "Link",
    link_to_call_record: "Link to call recording",
    listen: "Listen",
    live_feed: "Live feed",
    lost: "Lost",
    make_payment: "Make payment",
    male: "Male",
    male_short: "Male",
    manual_status: "Manual status",
    medication: "Treatment",
    middle_name: "Patronymic",
    mkb_code: "ICD code",
    move_to_visit: "To visit page",
    mphone: "Phone",
    ndfl_statement: "NDFL statement",
    ndfl_statement_new: "NDFL statement (new)",
    new: "New",
    new_patient: "New patient",
    next_visit: "Next visit",
    no_attachment: "No attachment",
    no_complexes: "No complexes",
    no_teeth_in_the_record: "The record has no teeth specified",
    norm: "Standard",
    not_confirmed: "Not confirmed",
    note: "Note",
    notes: "Notes",
    objectively: "Objectively",
    on_week: "For a week",
    or_write_other_reason: "or write another reason",
    oral_cavity: "Oral cavity",
    ortodentic_formula: "Orthodontic formula",
    ortodentic_formula_ru: "Orthodontic formula (RF)",
    other: "Other",
    overpayment: "Overpaid amount",
    paid: "Paid",
    passive: "Paasive",
    passport: "Passport",
    passport_data: "Passport data",
    passport_number: "Passport number",
    password_validation_error: "Password must be at least 8 characters long, have at least one capital letter, at least one digit and at least one special character",
    patient: "Patient",
    patient_condition_id: "Condition",
    patient_deleted: "Patient deleted",
    patient_fio: "Patient's full name",
    patient_has_important_notes: "Patient has important notes: {cnt}",
    patient_number: "Patient's number",
    patient_phone: "Patient's phone number",
    patient_source: "Where did he come from",
    payment: "Payment",
    payment_accepted: "Payment accepted",
    payment_date: "Payment date",
    payment_form: "Form of payment",
    payment_history: "Payment history",
    payment_processing: "Payment processing",
    payment_type: "Payment type",
    payments: "Payments",
    permanent_teeth: "Permanent teeth",
    personal_data: "Personal data",
    pesel: "PESEL",
    phone_2: "Additional phone number",
    pin: "Pin",
    pinned: "Pinned",
    plan_fact: "Plan / Fact",
    plan_notification: "Plan notification",
    planned_result: "Expected result",
    planned_send_date: "Planned sending date",
    planned_services: "Expected services",
    planned_treatment: "Expected treatment",
    preliminary: "Preliminary",
    price_price: "Price",
    prices_price: "Price",
    print_empty: "Print empty",
    process: "Process",
    questionnaire: "Questionnaire",
    quick_card: "Quick card",
    receipt: "Receipt",
    recommendations: "Recommendations",
    record: "Record",
    record_not_available: "Record not available",
    recorded: "Recorded",
    refresh_prices: "Refresh prices",
    region: "Region",
    regular_client: "Loyal customer",
    rejected: "Rejected",
    reopen_visit: "Reopen visit",
    representative: "Full name of Representative",
    representative_address: "Representative address",
    representative_document: "Details of the document certifying the right to represent the patient",
    representative_document_number: "Representative document number",
    representative_document_type: "Representative document type",
    representative_id: "Representative from existing users",
    representative_inn: "Representative INN",
    representative_noun: "Representative",
    representative_passport: "Representative passport",
    representative_passport_data: "Representative passport data",
    representative_phone: "Representative phone",
    request_snils: "Request SNILS",
    request_snils_note: "Getting SNILS from EGISZ - for that you need to fill in the patient's names, gender, date of birth and passport data (type, series, number, issue date and issue body)",
    restore: "Restore",
    results_not_found: "No results found",
    results_of_xray_and_lab_research: "X-ray and laboratory data",
    save_as_template: "Save as a template",
    save_as_treatment_template: "Save as treatment pattern",
    save_and_close: "Save and close",
    scan: "Scan",
    scans: "Scans",
    select_document_type: "Select document type",
    select_visit: "Select visit",
    selected_teeth: "Selected teeth",
    send: "Send",
    send_mail: "Send letter",
    send_marketing: "Send marketing materials",
    send_message: "Send message",
    send_notifications: "Send notifications",
    send_password_to_patient: "Send Password to the Patient",
    send_try: "Send attempt",
    series: "Series",
    service: "Service",
    services_payment_from: "Service payment from",
    set_the_amount: "Set the amount",
    size: "Size",
    sms_available: "Available SMS",
    sms_sending: "SMS sending",
    snils: "SNILS",
    standard: "Standard",
    start_treatment: "Start treatment",
    start_visit: "Start visit",
    stopped: "Stopped",
    subjectively: "Subjectevly",
    sum_with_discount: "Amount with a discount",
    superadministrator: "Superadministrator",
    surname: "Surname",
    short_name: "Short name",
    symbols_amount: "Number of symbols",
    tags_for_contract: "Tags for automatic display of information:",
    tags_for_document: "Tags for automatic display of information:",
    teeth: "Teeth",
    teeth_card: "Periodontal chart",
    termometry: "Thermometry",
    there_were_no_payments: "No payments yet",
    this_phone_is_in_system: "This number is already in the database",
    to_account: "To account",
    to_pay_with_bonus: "Pay with bonus",
    to_whom: "To whom",
    tonometry: "Tonometry",
    tooth: "Tooth",
    topic: "Theme",
    treatment_template: "Treatment pattern",
    tooth_is_assigned: "The tooth cannot be removed - services or diagnoses are already attached to it",
    tooth_formula: "Dental formula",
    top_baby_teeth: "Upper baby's teeth",
    top_jaw: "Upper jaw",
    top_molars: "Upper molar teeth",
    to_add_diagnoses_you_need_to_select_teeth: "You need to select teeth to add diagnoses",
    to_add_services_you_need_to_select_teeth: "You need to select teeth to add services",
    total_paid: "Total paid",
    total_paid_for_treatment_plan: "Total paid for treatment plan",
    total_services_selected: "Total services selected",
    total_to_pay: "Total to be paid",
    total_with_discount: "Total with the Discount",
    transfer_between_patient_accounts: "Transfer between patient accounts",
    transfer_whole_amount: "Transfer the entire amount",
    treating_doctor: "Treating Doctor",
    treatment: "Treatment",
    treatment_card: "Treatment card",
    treatment_complete: "Treatment completed",
    treatment_plan: "Treatment plan",
    un: "Unit",
    unify: "Combine",
    unify_card: "Combine the card",
    unpaid: "Unpaid",
    unpin: "Unpin",
    upload_date: "Upload date",
    used_medication: "Used medication",
    user_attached_to_family_account: "The user is linked to a family account",
    users_attached_to_user_family_account: "Patients linked to the user's family account",
    visit: "Visit",
    visit_date: "Visit date",
    visit_duration: "Duration of the visit (min)",
    visit_finished: "Visit finished",
    visit_in_progress: "Visit in progress",
    visit_started: "Visit started",
    visit_time: "Time of visit",
    visits: "Visits",
    waiting: "Waiting",
    waiting_list: "Waiting list",
    with_formula: "With formula",
    without_payment: "Without payment",
    write_reason: "Write the reason",
    xray: "X-Ray",
    years: "Years",
    you_need_to_select_teeth: "You need to select a tooth",
    zipcode: "Zip code",
}
