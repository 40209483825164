<template>
  <div class="tags-note">
    <slot/>
    <div v-for="m in menu"
         :key="`menu-${m.id}`">
      <div v-if="m.title" class="mt-3 font-weight-bold">{{ $t(m.title) }}:</div>
      <div v-else class="position-relative">
        <div class="copied position-absolute" v-if="copiedId === m.id">{{ $t('copied') }}</div>
        <span class="tag-code" @click="copyTag(m.id)"><span>{</span>{{m.id}}<span>}</span></span>
        - {{ $t(`tag_${m.custom_title ? m.custom_title : m.id}`) }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
  type: {
    type: String,
    required: true
  },
})

const copiedId = ref(null)
let timeout = null

const tags = {
  'agreement': [
    { title: 'general' },
    { id: 'DATA' },
    { id: 'DATE_FIRST_VIZIT' },
    { id: 'CLINIC' },
    { id: 'LEGAL_COMPANY_NAME' },
    { id: 'LEGAL_ADDRESS' },
    { id: 'DOCTOR' },
    { id: 'FIO_DIRECTOR' },
    { id: 'BRANCH_BIN' },
    { id: 'BRANCH_INN' },
    { id: 'BRANCH_KPP' },
    { id: 'BRANCH_OGRN' },
    { id: 'BRANCH_LICENSE' },
    { title: 'visit' },
    { id: 'DATA_VIZIT' },
    { id: 'VISIT_DOCTOR' },
    { id: 'USLUGI' },
    { id: 'USLUGI_PLAN' },
    { id: 'DIAGNOZ' },
    { id: 'DIAGNOZ_PLAN' },
    { id: 'COST_ORIGINAL' },
    { id: 'COST_DISCOUNTED' },
    { title: 'patient' },
    { id: 'FIO_CLIENT' },
    { id: 'PHONE_CLIENT' },
    { id: 'CARD_CLIENT' },
    { id: 'BIRTHDAY' },
    { id: 'ADDRESS' },
    { id: 'PASSPORT' },
    { id: 'PASSPORT_SERIES' },
    { id: 'PASSPORT_ISSUED' },
    { id: 'PASSPORT_DATE' },
    { id: 'PASSPORT_CODE' },
    { id: 'SNILS' },
    { id: 'IIN' },
    { id: 'INN' },
    { title: 'representative_noun' },
    { id: 'PREDSTAVITEL_PACIENTA' },
    { id: 'PHONE_PREDSTAVITEL' },
    { id: 'ADDRESS_PREDSTAVITEL' },
    { id: 'PASSPORT_PREDSTAVITEL' },
    { id: 'SNILS_PREDSTAVITEL' },
    { id: 'DOCUMENT_TYPE_PREDSTAVITEL' },
    { id: 'BIRTHDAY_PREDSTAVITEL' },
    { id: 'PASSPORT_CODE_PREDSTAVITEL' },
    { id: 'PASSPORT_SERIES_PREDSTAVITEL' },
    { id: 'PASSPORT_ISSUED_PREDSTAVITEL' },
    { id: 'PASSPORT_DATE_PREDSTAVITEL' },
  ],
  'contract': [
    { title: 'general' },
    { id: 'DATA' },
    { id: 'DATE_FIRST_VIZIT' },
    { id: 'CLINIC' },
    { id: 'LEGAL_COMPANY_NAME' },
    { id: 'LEGAL_ADDRESS' },
    { id: 'DOCTOR' },
    { id: 'FIO_DIRECTOR' },
    { id: 'BRANCH_BIN' },
    { id: 'BRANCH_INN' },
    { id: 'BRANCH_KPP' },
    { id: 'BRANCH_OGRN' },
    { id: 'BRANCH_LICENSE' },
    { title: 'patient' },
    { id: 'FIO_CLIENT' },
    { id: 'PHONE_CLIENT' },
    { id: 'CARD_CLIENT' },
    { id: 'BIRTHDAY' },
    { id: 'ADDRESS' },
    { id: 'PASSPORT' },
    { id: 'PASSPORT_SERIES' },
    { id: 'PASSPORT_ISSUED' },
    { id: 'PASSPORT_DATE' },
    { id: 'PASSPORT_CODE' },
    { id: 'SNILS' },
    { id: 'IIN' },
    { id: 'INN' },
    { title: 'representative_noun' },
    { id: 'PREDSTAVITEL_PACIENTA' },
    { id: 'PHONE_PREDSTAVITEL' },
    { id: 'ADDRESS_PREDSTAVITEL' },
    { id: 'PASSPORT_PREDSTAVITEL' },
    { id: 'SNILS_PREDSTAVITEL' },
    { id: 'DOCUMENT_TYPE_PREDSTAVITEL' },
    { id: 'BIRTHDAY_PREDSTAVITEL' },
    { id: 'PASSPORT_CODE_PREDSTAVITEL' },
    { id: 'PASSPORT_SERIES_PREDSTAVITEL' },
    { id: 'PASSPORT_ISSUED_PREDSTAVITEL' },
    { id: 'PASSPORT_DATE_PREDSTAVITEL' },
  ],
  "mailing": [
    { id: 'DATE' },
    { id: 'CLINIC' },
    { id: 'CLIENT' },
    { id: 'CLIENT_NAME' }
  ],
  "custom_notifications": [
    { id: 'DATE', custom_title: 'DATA_VIZIT' },
    { id: 'CLINIC' },
    { id: 'BRANCH_ADDRESS' },
    { id: 'CLIENT' },
    { id: 'CLIENT_NAME' },
    { id: 'CLIENT_BONUSES' },
    { id: 'DOCTOR' },
    { id: 'URL_VIZIT_CONFIRMATION' },
    { id: 'URL_VIZIT_REVIEW' },
  ],
  "notifications": [
    { id: 'DATE', custom_title: 'DATA_VIZIT' },
    { id: 'CLINIC' },
    { id: 'BRANCH_ADDRESS' },
    { id: 'CLIENT' },
    { id: 'CLIENT_NAME' },
    { id: 'CLIENT_BONUSES' },
    { id: 'DOCTOR' },
    { id: 'TASK' },
    { id: 'PHONE' },
    { id: 'PLAN_TITLE' },
    { id: 'PLAN_STATUS' },
    { id: 'COMPLEX_STATUS' },
    { id: 'URL_VIZIT_CONFIRMATION' },
    { id: 'URL_VIZIT_REVIEW' },
  ]
}

const copyTag = (tag) => {
  navigator.clipboard.writeText(`{${tag}}`)
  copiedId.value = tag
  if(timeout) clearTimeout(timeout)
  timeout = setTimeout(() => {
    copiedId.value = null
  }, 1300)
}

const menu = computed(() => {
  return tags[props.type]
})
</script>

<style scoped lang="scss">
.copied {
  display: inline-block;
  top: -15px;
  background: #000;
  color: #fff;
  border-radius: 5px;
  padding: 3px 6px;
  left: 40px;
}
</style>
