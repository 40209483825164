import Vue from "vue"
import { doctorAdapter } from "@/adapters/staff/doctor"

import options from "@/data/staff/doctor"
let curatorOptions = Vue.prototype.copyObject(options)
curatorOptions.model = 'curator'
curatorOptions.fields.forEach(item => {
    if(['warehouse_id','doctors_profession_id', 'prices'].includes(item.id)) delete item.form
    if(['full_name'].includes(item.id)) {
        item.tdcomponent = 'StaffDatatableTDStaffName'
    }
    if(item.form && item.form.type === 'separator') {
        delete item.form
    }
})
curatorOptions.actionscomponent = null
curatorOptions.adapter = doctorAdapter
export default curatorOptions
