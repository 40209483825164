import store from "@/store"

export default function auth({ next, router, to }) {
    if(!store || !store?.state?.auth?.isLogged) {
        router.push('/login').catch(()=>{})
        return
    }
    if(to.meta.rights && Array.isArray(to.meta.rights)) {
        to.meta.rights.forEach(item => {
            if(!store.state.auth.rights || !store.state.auth.rights.includes(item)) {
                router.push('/login').catch(()=>{})
                return
            }
        })
    }
    return next()
}
