<template>
  <spaceduck-collapse class="filter-collapse-general"
              :id="`filter-collapse-${viewModel}`"
              :visible="visible">
    <div class="pb-md-4 filter-wrapper-pb">
      <div class="filter-wrapper">
        <a class="filter-wrapper-close"
           v-spaceduck-collapse="`filter-collapse-${viewModel}`">
          <CloseSvg/><span class="d-md-none d-inline">{{ $t('filter') }}</span>
        </a>
        <b-row no-gutters>
          <b-col class="order-md-0 order-1 no-print" cols="12">
            <b-row class="align-items-center">
              <b-col cols="12"
                     md="auto"
                     class="order-md-0 order-2">
                <button class="btn btn-themed d-md-inline-flex d-flex refresh-btn align-items-center"
                        @click="refresh">
                  <RefreshSvg class="mr-2"/>
                  {{ $t('refresh') }}
                </button>
              </b-col>
              <b-col cols="12"
                     md="auto"
                     class="order-md-1 order-0 mt-md-0 mt-3">
                <a class="a-themed" @click="addCondition">
                  <PlusSvg class="mr-2 d-inline-block align-middle"/>
                  <span class="d-inline-block align-middle">{{ $t('add_condition') }}</span>
                </a>
              </b-col>
              <b-col cols="12"
                     md="auto"
                     class="order-md-2 order-1 mt-md-0 mt-3"
                     v-if="$route.name === 'MarketingGroup'">
                <a class="a-themed" @click="saveFilter">
                  <FloppySvg class="mr-2 d-inline-block align-middle"/>
                  <span class="d-inline-block align-middle">{{ $t('save_filter') }}</span>
                </a>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="order-md-1 order-0">
            <b-row>
              <b-col cols="12" md="auto"
                     class="mt-3"
                     v-for="f in filters"
                     :key="`filter-selected-${f.id}`">
                <div class="filter-field">
                  <b-row no-gutters class="align-items-center">
                    <b-col cols="auto" md="auto">
                      <div class="filter-title">{{ $t(f.title) }}:</div>
                    </b-col>
                    <b-col cols="auto">
                      <select v-model="f.ctype" class="select-type">
                        <option v-for="o in getCTypes(f.type, f.id)"
                                :value="o.id"
                                :key="`ctype-${f.id}-${o.id}`">
                          {{ $t(o.title) }}
                        </option>
                      </select>
                    </b-col>
                    <b-col col md="auto">
                      <div v-if="f.type === 'select' || f.type === 'select_equals'" class="filter-value">
                        <select v-model="f.condition"
                                :multiple="['contains', 'does_not_contain'].includes(f.ctype)">
                          <option value="">{{ $t('select') }}</option>
                          <option v-for="o in f.options"
                                  :key="`cond-${f.id}-${o.id}`"
                                  :value="o.id">
                            {{ f.translate ? $t(o.title) : o.title }}
                          </option>
                        </select>
                      </div>
                      <div v-else-if="f.type === 'boolean' || f.ctype === 'filled'" class="filter-value">
                        <select v-model="f.condition">
                          <option value="0">{{ $t('no') }}</option>
                          <option value="1">{{ $t('yes') }}</option>
                        </select>
                      </div>
                      <DateGroup v-else-if="f.type === 'date'"
                                 class="simple-input"
                                 :showlabel="false"
                                 :calendarIcon="false"
                                 v-model="f.condition"/>
                      <TimeGroup v-else-if="f.type === 'time'"
                                 class="simple-input"
                                 :showlabel="false"
                                 :calendarIcon="false"
                                 v-model="f.condition"/>
                      <input v-else
                             @keyup.enter="refresh"
                             v-model="f.condition"/>
                    </b-col>
                    <b-col cols="auto">
                      <a class="filter-close" @click="removeFilter(f.id)">
                        <CloseSvg/>
                      </a>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal :id="`filter-modal-${viewModel}`"
             :title="$t('filter')"
             hide-footer>
      <template v-for="f in options.fields">
        <a class="option-list-item"
           :key="`filter-option-${f.id}`"
           @click="filterSelected(f)"
           v-if="f.filter && !filters[f.id] && !f.filter_hidden">
          {{ $t(f.title) }}
        </a>
      </template>
    </b-modal>
  </spaceduck-collapse>
</template>

<script>

import { mapState } from "vuex"
import RefreshSvg from '@/assets/svg-vue/general/refresh.svg'
import FloppySvg from '@/assets/svg-vue/general/floppy.svg'
import PlusSvg from '@/assets/svg-vue/header/plus.svg'
import CloseSvg from '@/assets/svg-vue/general/close.svg'
import { EloquentService } from "@/services/api.service"
import DateGroup from "@/components/form/DateGroup"
import TimeGroup from "@/components/form/TimeGroup.vue"
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"

export default {
  name: "DataTableFilter",
  components: {
    TimeGroup,
    DateGroup,
    RefreshSvg,
    PlusSvg,
    CloseSvg,
    FloppySvg,
    SpaceduckCollapse
  },
  props: {
    options: {
      type: Object
    },
    cacheId: {
      type: String,
      default: null
    },
    considerInitialFilters: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      filters: {},
      prepared: false,
      visible: false,
    }
  },
  computed: {
    ...mapState({
      viewModel: state => state.datatable.viewModel,
      selectModels: state => state.datatable.selectModels,
      filtersCache: state => state.datatable.filtersCache,
      superadmin: state => state.auth.superadmin,
    }),
    hasInitialFilters() {
      // return !!this.$route.query.filters
      return this.$route.query.filters && this.$route.query.filters != '{}' && this.considerInitialFilters
    },
    filterCached() {
      return this.filtersCache[this.cacheKey] ?? null
    },
    routeName() {
      return this.$route.name
    },
    cacheKey() {
      return this.cacheId || this.$route.name
    }
  },
  watch: {
    viewModel: {
      handler(val) {
        if(val) {
          if(this.hasInitialFilters || (this.filterCached && Object.keys(this.filterCached).length > 0)) {
            this.visible = true
            this.prepare().then(() => {
              let routeFilters
              if(this.hasInitialFilters) {
                routeFilters = JSON.parse(this.$route.query.filters)
              } else {
                routeFilters = this.filterCached
              }
              for(let r in routeFilters) {
                this.options.fields.forEach(item => {
                  if(item.id == r) {
                    this.filterSelected(item, routeFilters[r].condition, routeFilters[r].type)
                  }
                })
              }
              this.filtersToData()
              this.$emit('filterReady')
            })
          } else {
            this.$emit('filterReady')
          }
        }
      },
      immediate: true
    },
  },
  methods: {
    refresh() {
      const data = this.filtersToData()
      if(!this.options.doNotCacheFilter) {
        this.$store.commit('setFiltersCache', { id: this.cacheKey, value: data })
      }
      this.$router.push({ path: this.$route.path, query: {
          filters: JSON.stringify(data)
        }
      }).catch(()=>{})
      if(window && window.innerWidth <= 768) {
        this.$root.$emit('bv::toggle::collapse', `filter-collapse-${this.viewModel}`)
      }
    },
    filtersToData() {
      let data = {}
      for(const f in this.filters) {
        let obj = this.filters[f]
        data[obj.id] = {
          id:         obj.id,
          condition:  obj.condition,
          type:       obj.ctype
        }
      }
      this.$store.commit('setFilters', data)
      return data
    },
    removeFilter(id) {
      this.$delete(this.filters, id)
      this.refresh()
    },
    filterSelected(filter, condition = '', ctype = null) {
      let meta = this.getOptions(filter.id)
      let id = filter.filter_field_id ?? filter.id
      let default_type = 'like'
      if(meta.type === 'select') { default_type = 'equals' }
      if(meta.type === 'select_equals') { default_type = 'equals' }
      if(meta.type === 'number') { default_type = 'equals' }
      if(meta.type === 'date') { default_type = 'equals' }
      if(meta.type === 'time') { default_type = 'equals' }
      if(meta.type === 'boolean') { default_type = 'equals'; if(condition != 1) { condition = 0; } }
      if(ctype) default_type = ctype
      this.$set(this.filters, id, {
        id:         id,
        title:      filter.title,
        condition:  condition,
        translate:  meta.translate,
        type:       meta.type,
        ctype:      default_type,
        options:    meta.options
      })
      this.$bvModal.hide('filter-modal-'+this.viewModel)
    },
    addCondition() {
      if(!this.prepared) {
        this.prepare().then(this.$bvModal.show('filter-modal-'+this.viewModel))
      } else {
        this.$bvModal.show('filter-modal-'+this.viewModel)
      }
    },
    saveFilter() {
      this.$store.commit('incrementSaveFilterCounter')
    },
    prepare() {
      return new Promise(resolve => {
        EloquentService.prepare(this.viewModel, {}, this.superadmin).then(res => {
          this.prepared = true
          this.$store.commit("setSelectModels", Array.isArray(res.data.options) ? {} : res.data.options)
          resolve()
        })
      })
    },
    getOptions(id) {
      let res = {
        type: 'text'
      }
      this.options.fields.forEach(item => {
        if(item.id === id) {
          if(item.filter_list_select) {
            res = {
              type: 'select',
              translate: true,
              options: Object.values(item.filter_list_options || item.form.options)
            }
          }
          if(item.filter_list_select_equals) {
            res = {
              type: 'select_equals',
              translate: true,
              options: Object.values(item.form.options)
            }
          }
          if(item.filter_model_select) {
            res = {
              type: 'select',
              translate: false,
              options: this.selectModels[id] ? this.selectModels[id] : []
            }
          }
          if(item.filter_model_select_translate) {
            res = {
              type: 'select',
              translate: true,
              options: this.selectModels[id] ? this.selectModels[id] : []
            }
          }
          if(item.filter_model_select_equals) {
            res = {
              type: 'select_equals',
              translate: false,
              options: this.selectModels[id] ? this.selectModels[id] : []
            }
          }
          if(item.filter_number) {
            res = {
              type: 'number'
            }
          }
          if(item.filter_boolean) {
            res = {
              type: 'boolean'
            }
          }
          if(item.filter_date) {
            res = {
              type: 'date'
            }
          }
          if(item.filter_time) {
            res = {
              type: 'time'
            }
          }
        }
      })
      return res
    },
    getCTypes(type, id) {
      if(['date_from','date_to','task_date'].includes(id)) {
        return {
          'equals': { id: 'equals', title: 'equals' },
        }
      }
      switch(type) {
        case 'text':
          return {
            'equals': { id: 'equals', title: 'equals' },
            'like': { id: 'like', title: 'contains' },
            'filled': { id: 'filled', title: 'filled' },
          }
        case 'select':
          return {
            'equals': { id: 'equals', title: 'equals' },
            'does_not_equal': { id: 'does_not_equal', title: 'does_not_equal' },
            'contains': { id: 'contains', title: 'contains' },
            'does_not_contain': { id: 'does_not_contain', title: 'does_not_contain' },
          }
        case 'number':
        case 'date':
        case 'time':
          return {
            'equals': { id: 'equals', title: 'equals' },
            'more': { id: 'more', title: 'more' },
            'less': { id: 'less', title: 'less' },
          }
        case 'select_equals':
        case 'boolean':
          return {
            'equals': { id: 'equals', title: 'equals' },
          }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.filter-wrapper {
  padding: 16px 23px;
  border: 1px solid $gray-icon;
  background: $body-bg;
  border-radius: 5px;
  position: relative;
  .filter-wrapper-close {
    position: absolute;
    padding-right: 23px;
    padding-top: 21px;
    z-index: 2;
    cursor: pointer;
    right: 0;
    top: 0;
    svg {
      width: 14px;
      height: 14px;
      fill: $label-color;
    }
  }
}
.option-list-item {
  display: block;
  line-height: 42px;
  color: #000;
  cursor: pointer;
  padding: 0 15px;
  border-top: 1px solid $calendar-border-color;
  &:last-of-type {
    border-bottom: 1px solid $calendar-border-color;
  }
  &:hover {
    text-decoration: none;
    background: $light-blue;
  }
}
.filter-field {
  border: 1px solid $gray-icon;
  background: #fff;
  border-radius: 3px;
  .filter-title {
    color: $gray-text;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 10px;
  }
  .filter-value {
    select, input {
      background-color: #ededed;
      border-radius: 5px;
    }
  }
  input {
    border: none;
    background: transparent;
    outline: none;
    font-size: 16px;
    max-width: 90px;
  }
  select {
    border: none;
    margin-right: 10px;
    outline: none;
    &.select-type {
      background: #E8F2FC;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-transform: lowercase;
      padding: 3px;
      font-weight: 600;
      border-radius: 5px;
      line-height: 1rem;
    }
  }
  .filter-close {
    cursor: pointer;
    height: 38px;
    width: 38px;
    line-height: 36px;
    text-align: center;
    display: block;
    border-left: 1px solid $gray-icon;
    svg {
      width: 16px;
      height: 16px;
      fill: $gray-icon;
    }
  }
}
@media screen and (max-width: 768px) {
  .filter-collapse-general {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    .filter-wrapper-pb, .filter-wrapper {
      height: 100%;
    }
  }
  .filter-wrapper {
    padding-top: 0;
    border: none;
    .filter-wrapper-close {
      z-index: 2;
      position: relative;
      margin-bottom: 20px;
      border-bottom: 1px solid $gray-icon;
      padding: 15px 23px;
      //padding-top: 15px;
      //padding-bottom: 15px;
      display: block;
      background: #fff;
      margin-left: -23px;
      margin-right: -23px;
      span {
        margin-left: 15px;
        font-size: 15px;
        color: #000;
      }
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
  .refresh-btn {
    width: 100%;
    border-radius: 5px;
    margin-top: 28px;
    justify-content: center;
    height: 36px;
  }
  .filter-field {
    .filter-title {
      font-size: 12px;
    }
    .filter-close {
      height: 36px;
      width: 38px;
      line-height: 34px;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}
</style>
