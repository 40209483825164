<template>
  <div class="d-md-inline-block d-block position-relative align-middle table-search-wrapper" v-if="options">
    <SearchSvg/>
    <input type="text"
           class="form-control table-search-input"
           v-model="search"
           :placeholder="$t('table_search')+'...'"/>
  </div>
</template>

<script>
import SearchSvg from '@/assets/svg-vue/header/magnifier.svg'
import { mapState } from "vuex"

export default {
  name: "SearchTable",
  components: {
    SearchSvg
  },
  data() {
    return {
      search: '',
    }
  },
  watch: {
    routeName() {
      this.search = ''
    },
    search(val) {
      this.$store.commit('setSearch',val)
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    },
    ...mapState({
      options: state => state.datatable.eloquentOptions
    }),
  }
}
</script>

<style scoped>

</style>
