<template>
  <div v-if="hasKKMRight" class="mb-3">
    <b-button class="btn-collapse" v-spaceduck-collapse="`collapse-kkm`">KKM</b-button>
    <spaceduck-collapse id="collapse-kkm">
      <div class="pt-4 pb-4" v-if="initiated">
        <CheckboxGroup :label="$t('on')"
                       checkboxvalue="1"
                       fid="kkm"
                       v-model="kkm.kkm_enabled"/>
        <div class="pt-3" v-if="kkm.kkm_enabled">
          <InputGroup v-model="kkm.kkm_service"
                      class="mb-3"
                      :label="$t('name_of_service_in_cheque')"/>
          <InputGroup v-model="kkm.kkm_clinic_name"
                      class="mb-3"
                      :label="$t('clinic_name_in_cheque')"/>
          <InputGroup v-model="kkm.kkm_cashier_name"
                      class="mb-3"
                      :label="$t('cashier_name')"/>
          <InputGroup v-model="kkm.kkm_cashier_inn"
                      class="mb-3"
                      :label="$t('cashier_inn')"/>
          <ServiceMultiSelect :label="$t('pay_methods_cash')"
                              :dblclick="false"
                              model="pay_method"
                              title="translatedTitle"
                              class="mb-3"
                              :dbsearch="false"
                              :prepopulatedOptions="pay_methods"
                              v-model="kkm.kkm_cashmethods"/>
          <ServiceMultiSelect :label="$t('pay_methods_acquiring')"
                              :dblclick="false"
                              model="pay_method"
                              title="translatedTitle"
                              class="mb-3"
                              :dbsearch="false"
                              :prepopulatedOptions="pay_methods"
                              v-model="kkm.kkm_acquiring_methods"/>
          <SelectGroup :options="kkmTaxModes"
                       :label="$t('vat_rate')"
                       class="mb-3"
                       variant="white"
                       :showlabel="true"
                       v-model="kkm.kkm_vat"/>
          <CheckboxGroup :label="$t('send_services_in_cheque')"
                         fid="kkm_send_services"
                         class="mb-3"
                         checkboxvalue="1"
                         v-model="kkm.kkm_send_services"/>
        </div>
        <div class="mt-4">
          <button class="btn btn-themed btn-themed-squared"
                  :disabled="loading"
                  @click="saveWebkassa">
            {{ $t('save') }}
          </button>
        </div>
      </div>
    </spaceduck-collapse>
  </div>
</template>

<script>
import { ClinicService } from "@/services/api.service"
import { mapState } from "vuex"
import InputGroup from "@/components/form/InputGroup.vue"
// import SelectGroup from "@/components/form/SelectGroup"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect.vue"
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"
import CheckboxGroup from "@/components/form/CheckboxGroup.vue"
import SelectGroup from "@/components/form/SelectGroup.vue"
import { kkmTaxModes } from "@/dictionaries/dictionary"

export default {
  name: "KKMIntegration",
  components: {
    SelectGroup,
    CheckboxGroup,
    SpaceduckCollapse,
    ServiceMultiSelect,
    InputGroup,
    // SelectGroup
  },
  computed: {
    ...mapState({
      clinic: state   => state.auth.clinic,
      superadmin: state => state.auth.superadmin,
      rights: state => state.auth.rights,
      loading: state => state.dom.loading,
      ppoData: state => state.ppo.ppoData,
      pay_methods: state => state.auth.pay_methods,
      branch: state => state.auth.branch
    }),
    standardSettings() {
      return this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : {
        [`kkm_enabled${this.branch}`]: 0,
        [`kkm_send_services${this.branch}`]: 0,
        [`kkm_vat${this.branch}`]: -1,
        [`kkm_service${this.branch}`]: '',
        [`kkm_clinic_name${this.branch}`]: '',
        [`kkm_cashier_name${this.branch}`]: '',
        [`kkm_cashier_inn${this.branch}`]: '',
        [`kkm_cashmethods${this.branch}`]: [],
        [`kkm_acquiring_methods${this.branch}`]: [],
      }
    },
    hasKKMRight() {
      return this.rights.includes('kkm')
    }
  },
  data() {
    return {
      kkmTaxModes,
      kkm: {
        kkm_enabled: '',
        kkm_send_services: 0,
        kkm_service: '',
        kkm_clinic_name: '',
        kkm_cashmethods: '',
        kkm_acquiring_methods: '',
        kkm_vat: '',
      },
      initiated: false,
      cashdesks: []
    }
  },
  methods: {
    async saveWebkassa() {
      await ClinicService.savePPOAttribute([
        `kkm_enabled${this.branch}`,
        `kkm_send_services${this.branch}`,
        `kkm_service${this.branch}`,
        `kkm_clinic_name${this.branch}`,
        `kkm_cashier_name${this.branch}`,
        `kkm_cashier_inn${this.branch}`,
        `kkm_cashmethods${this.branch}`,
        `kkm_acquiring_methods${this.branch}`,
        `kkm_vat${this.branch}`,
      ], [
        this.kkm.kkm_enabled,
        this.kkm.kkm_send_services,
        this.kkm.kkm_service,
        this.kkm.kkm_clinic_name,
        this.kkm.kkm_cashier_name,
        this.kkm.kkm_cashier_inn,
        this.kkm.kkm_cashmethods.map(x => x.id),
        this.kkm.kkm_acquiring_methods.map(x => x.id),
        this.kkm.kkm_vat,
      ], this.superadmin, this.clinic.id)
      this.$noty.info(this.$t('success_message'))
      await this.getCashdesks()
      this.$emit('update')
    },
    async getCashdesks() {
      // const res = await CashdeskService.webkassaData('cashdesks')
      // this.cashdesks = res.data
    }
  },
  async mounted() {
    await this.getCashdesks()
    this.kkm.kkm_enabled = this.standardSettings[`kkm_enabled${this.branch}`]
    this.kkm.kkm_send_services = this.standardSettings[`kkm_send_services${this.branch}`] || '0'
    this.kkm.kkm_service = this.standardSettings[`kkm_service${this.branch}`]
    this.kkm.kkm_clinic_name = this.standardSettings[`kkm_clinic_name${this.branch}`]
    this.kkm.kkm_cashier_name = this.standardSettings[`kkm_cashier_name${this.branch}`]
    this.kkm.kkm_cashier_inn = this.standardSettings[`kkm_cashier_inn${this.branch}`]
    this.kkm.kkm_vat = this.standardSettings[`kkm_vat${this.branch}`]
    this.kkm.kkm_cashmethods = []
    let cashMethods = this.standardSettings[`kkm_cashmethods${this.branch}`]
    if(cashMethods) this.kkm.kkm_cashmethods = this.pay_methods.filter(p => cashMethods.includes(p.id))
    this.kkm.kkm_acquiring_methods = []
    let acquiringMethods = this.standardSettings[`kkm_acquiring_methods${this.branch}`]
    if(acquiringMethods) this.kkm.kkm_acquiring_methods = this.pay_methods.filter(p => acquiringMethods.includes(p.id))
    this.initiated = true
  }
}
</script>
