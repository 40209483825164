<template>
  <div>
    <div class="mb-3" v-if="clinic.import_services == 1 && importAllowed">
      <b-dropdown variant="link"
                  right
                  text="Dropdown Button">
        <template #button-content>
          <a class="btn-themed btn-themed-outline btn-themed-squared d-inline-block">
            {{  $t('import_standard_services') }}<ArrowSvg class="ml-2"/>
          </a>
        </template>
        <b-dropdown-item @click="importStandardServices">{{ $t('import') }}</b-dropdown-item>
        <b-dropdown-item @click="closeImport">{{ $t('close') }}</b-dropdown-item>
      </b-dropdown>

    </div>
    <DataTable :options="options"
               :params="{ visible: 1 }"
               ref="datatable"
               @checkboxSelected="selectedChange"
               module="services"/>


    <div class="bottom-bar" v-if="selected.length">
      <div class="container py-3">
        <b-row class="align-items-center">
          <b-col>{{ $t('selected') }}: {{ selected.length }}</b-col>
          <b-col cols="auto">
            <b-dropdown variant="link">
              <template #button-content>
                <a class="pointer btn-themed btn-themed-outline btn-themed-squared d-inline-block">
                  {{ $t('actions_with_selected') }}
                </a>
              </template>
              <b-dropdown-item @click="edit"
                               v-if="rights.includes('clinic_services')">
                {{ $t('edit') }}
              </b-dropdown-item>
              <b-dropdown-item v-if="rights.includes('clinic_services')"
                               @click="sendToArchive">
                {{ $t('send_to_archive') }}
              </b-dropdown-item>
              <b-dropdown-divider/>
              <b-dropdown-item @click="resetSelection">
                {{ $t('reset_selection') }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </div>
    </div>

    <ServicesBulkModal :selected="selected"
                       @finished="bulkPostChange"/>

  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/services/service"
import {ClinicService, EloquentService} from "@/services/api.service"
import {mapState} from "vuex"
import ArrowSvg from '@/assets/svg-vue/general/arrow_down.svg'
import ServicesBulkModal from "@/components/parts/services/ServicesBulkModal.vue"

export default {
  name: "ServiceServices",
  head() {
    return {
      title: this.$t('services'),
    }
  },
  components: {
    ServicesBulkModal,
    DataTable,
    ArrowSvg
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      branch: state => state.auth.branch,
      rights: state => state.auth.rights,
    }),
  },
  data() {
    return {
      options: options,
      importAllowed: false,
      selected: []
    }
  },
  methods: {
    async importStandardServices() {
      let res = await ClinicService.importStandardServices()
      if(res.data) {
        this.$noty.info(this.$t('success_message'))
        await this.$store.dispatch('init')
        this.$store.commit('incrementDataTableCounter')
      }
    },
    async closeImport() {
      await ClinicService.disableStandardServices()
      await this.$store.dispatch('init')
    },
    selectedChange(selected) {
      this.selected = selected
    },
    edit() {
      this.$bvModal.show('services-bulk-edit-modal')
    },
    async sendToArchive() {
      const answer = confirm(this.$t('are_you_sure_you_want_to_delete_service'))
      if(!answer) return
      await EloquentService.deleteBulk('service', this.selected)
      this.$store.commit('incrementDataTableCounter')
    },
    bulkPostChange() {
      this.$bvModal.hide('services-bulk-edit-modal')
      this.resetSelection()
      this.$store.commit('incrementDataTableCounter')
    },
    resetSelection() {
      this.$refs.datatable.cancelSelection()
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
    this.$store.commit('setAdditionalParams', { visible: 1 })
  },
  beforeUnmount() {
    this.$store.commit('setAdditionalParams', {})
  }
}
</script>
