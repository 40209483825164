export default {
    tag_ADDRESS: "Адрес пациента",
    tag_ADDRESS_PREDSTAVITEL: "Адрес представителя клиента",
    tag_BIRTHDAY: "Дата рождения пациента",
    tag_BIRTHDAY_PREDSTAVITEL: "Дата рождения представителя пациента",
    tag_BRANCH_ADDRESS: "Адрес филиала",
    tag_BRANCH_BIN: "БИН филиала",
    tag_BRANCH_INN: "ИНН филиала",
    tag_BRANCH_KPP: "КПП филиала",
    tag_BRANCH_LICENSE: "Лицензия филиала",
    tag_BRANCH_OGRN: "ОГРН филиала",
    tag_CARD_CLIENT: "Номер карточки клиента",
    tag_CLIENT: "Ф.И.О. клиента",
    tag_CLIENT_BONUSES:  "Бонусы клиента",
    tag_CLIENT_NAME: "Имя клиента",
    tag_CLINIC: "Название клиники",
    tag_COMPLEX_STATUS: "Статус комплекса плана лечения",
    tag_COST_DISCOUNTED: "Стоимость услуг с учетом скидки",
    tag_COST_ORIGINAL: "Стоимость услуг без учета скидки",
    tag_DATA: "Дата создания документа",
    tag_DATA_VIZIT: "Дата визита",
    tag_DATE: "Дата создания рассылки",
    tag_DATE_FIRST_VIZIT: "Дата первого визита",
    tag_DIAGNOZ: "Диагноз",
    tag_DIAGNOZ_PLAN: "Диагноз из плана лечения",
    tag_DOCTOR: "Ф.И.О. – фио доктора",
    tag_DOCUMENT_TYPE_PREDSTAVITEL: "Тип документа представителя пациента",
    tag_FIO_CLIENT: "Ф.И.О. клиента",
    tag_FIO_DIRECTOR: "Ф.И.О. директора",
    tag_IIN: "ИИН пациента",
    tag_INN: "ИНН пациента",
    tag_LEGAL_ADDRESS: "Юридический адрес клиники",
    tag_LEGAL_COMPANY_NAME: "Юридическое название клиники",
    tag_PASSPORT: "Номер паспорта пациента",
    tag_PASSPORT_CODE: "Код подразделения паспорта пациента",
    tag_PASSPORT_CODE_PREDSTAVITEL: "Код подразделения паспорта представителя клиента",
    tag_PASSPORT_DATE: "Дата выдачи паспорта пациента",
    tag_PASSPORT_DATE_PREDSTAVITEL: "Дата выдачи документа представителя пациента",
    tag_PASSPORT_ISSUED: "Кем выдан паспорт пациента",
    tag_PASSPORT_ISSUED_PREDSTAVITEL: "Кем выдан документ представителя пациента",
    tag_PASSPORT_PREDSTAVITEL: "Номер паспорта представителя клиента",
    tag_PASSPORT_SERIES: "Серия паспорта пациента",
    tag_PASSPORT_SERIES_PREDSTAVITEL: "Серия документа представителя клиента",
    tag_PHONE: "Телефон клиента",
    tag_PHONE_CLIENT: "Телефон клиента",
    tag_PHONE_PREDSTAVITEL: "Телефон представителя клиента",
    tag_PLAN_STATUS: "Статус плана лечения",
    tag_PLAN_TITLE: "Название плана лечения",
    tag_PREDSTAVITEL_PACIENTA: "Ф.И.О. представителя клиента",
    tag_SNILS: "СНИЛС пациента",
    tag_SNILS_PREDSTAVITEL: "СНИЛС представителя пациента",
    tag_TASK: "Текст задачи",
    tag_URL_VIZIT_CONFIRMATION: "Ссылка на подтверждение визита",
    tag_URL_VIZIT_REVIEW: "Ссылка на отзыв о визите",
    tag_USLUGI: "Услуги",
    tag_USLUGI_PLAN: "Услуги из плана лечения",
    tag_VISIT_DOCTOR: "Ф.И.О. доктора визита",
}
