export const ckeditorConfig = {
    allowedContent: 'table tbody tr td th thead strong a span ul ol p li div h1 h2 h3 h4 h5 h6[*]{*}',
    extraPlugins: ['font','justify','colorbutton','lineheight'],
    height: 300,
    language: 'ru',
    toolbarGroups: [
        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
        { name: 'forms', groups: [ 'forms' ] },
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
        { name: 'links' },
        { name: 'insert', groups: ['table'] },
        { name: 'colors', groups: [ 'colors' ] },
        { name: 'tools', groups: [ 'tools' ] },
        { name: 'styles', groups: [ 'styles' ] },
        { name: 'others', groups: [ 'others' ] },
        { name: 'font'},
        // { name: 'lineheight'},
    ],
    line_height: "0.5;1;1.5;2;2.5;3;3.5;4;4.5;5;6;7;8;9;10;12;14;16;18;20;25;30",
    removeButtons: 'Styles,About'
}
