import authorized from "@/middleware/authorized"
import paid from '@/middleware/paid'

import CashdeskGeneral from "@/components/pages/cashdesk/CashdeskGeneral"
import CashdeskSettings from "@/components/pages/cashdesk/CashdeskSettings"
import CashdeskCheques from "@/components/pages/cashdesk/CashdeskCheques"
import CashdeskXReports from "@/components/pages/cashdesk/CashdeskXReports"
import CashdeskShifts from "@/components/pages/cashdesk/CashdeskShifts"
import CashdeskKKMShifts from "@/components/pages/cashdesk/kkm/CashdeskKKMShifts"
import CashdeskKKMReports from "@/components/pages/cashdesk/kkm/CashdeskKKMReports"
import CashdeskKKMOperations from "@/components/pages/cashdesk/kkm/CashdeskKKMOperations"

export default {

    path: '/cashdesk',
    meta: { middleware: [authorized, paid], layout: "default", rights: ['cashdesk'] },
    // component:() => import('../components/pages/cashdesk/CashdeskGeneral.vue'),
    component: CashdeskGeneral,
    children: [
        { path: '', redirect: '/cashdesk/settings' },
        {
            path: 'settings', name: "CashdeskSettings",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['cashdesk'] },
            // component: () => import('../components/pages/cashdesk/CashdeskSettings.vue')
            component: CashdeskSettings
        },
        {
            path: 'cheques', name: "CashdeskCheques",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['cashdesk'] },
            // component: () => import('../components/pages/cashdesk/CashdeskCheques.vue')
            component: CashdeskCheques
        },
        {
            path: 'xreports', name: "CashdeskXReports",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['cashdesk'] },
            // component: () => import('../components/pages/cashdesk/CashdeskXReports.vue')
            component: CashdeskXReports
        },
        {
            path: 'shifts', name: "CashdeskShifts",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['cashdesk'] },
            // component: () => import('../components/pages/cashdesk/CashdeskShifts.vue')
            component: CashdeskShifts
        },
        {
            path: 'kkm/shifts', name: "CashdeskKKMShifts",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['cashdesk', 'kkm'] },
            component: CashdeskKKMShifts
        },
        {
            path: 'kkm/operations', name: "CashdeskKKMOperations",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['cashdesk', 'kkm'] },
            component: CashdeskKKMOperations
        },
        {
            path: 'kkm/reports', name: "CashdeskKKMReports",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['cashdesk', 'kkm'] },
            component: CashdeskKKMReports
        },
    ]
}
