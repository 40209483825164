<template>
  <section id="calendar"
           :class="[{ showOverflow: showOverflowScreen}, 'cursor-'+selectedCursor]"
           class="calendar pb-5 position-relative"
           :style="{cursor: selectedCursor}">
    <div v-if="isLoading" class="loading-block"></div>
    <CalendarViewSwitch/>
    <b-row class="align-items-center section-top-row row-narrow"
           id="calendar-top-menu"
           :class="{ stickyCalendar }">
      <b-col xl lg="6" cols="12" order-lg="0" order="0">
        <h1 class="d-flex flex-row">
          <span class="d-inline-block mr-md-3 mr-2"
                @click="$store.commit('setRefreshCalendar', true)">{{ $t('schedule') }}:</span>
          <DRangeSwitcher class="d-inline-block"/>
          <DViewSwitcher class="d-inline-block"
                         v-if="scheduleView === 'doctors'"/>
          <DDaysSwitcher class="d-inline-block"
                         v-if="activeRange === 'DAY' && daysSwitcherEnabled"/>
          <DProfessionSwitcher class="d-inline-block"
                               v-if="activeRange === 'DAY' && scheduleView === 'doctors'"/>
        </h1>
      </b-col>
      <b-col xl="auto" lg="6" cols="12" order-lg="1" order="1">
        <b-row class="mt-lg-0 mt-2 row-narrow">
          <b-col lg="auto" cols="5" order-lg="1" order="1">
            <DDoctors :on-save-list="requestData" />
          </b-col>
          <b-col lg="auto" cols="5" order-lg="2" order="3" class="d-lg-block d-none">
            <CalendarToday/>
          </b-col>
          <b-col lg="auto" cols="5" order-lg="3" order="2">
            <CalendarNavigator/>
          </b-col>
          <b-col lg="auto" cols="auto" order-lg="4" order="3" class="text-right" v-if="scheduleView === 'doctors'">
            <DCalendarSettings />
          </b-col>
          <b-col lg="auto" cols="auto" order-lg="5" order="4" class="" v-if="scheduleView === 'doctors'">
            <a class="btn-themed d-inline-block new-visit-btn"
               v-if="rights.includes('add_records')"
               @click="addVisit">
              <CalendarNSvg class="mr-xl-2"/>
              <span class="d-none d-xl-inline">{{ $t('new_visit') }}</span>
            </a>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="alert alert-warning mt-4"
         v-if="!isLoading && !doctors.length"
         v-html="$t('you_have_no_doctors', { href: '/staff/doctors' })"></div>
    <b-row class="d-body-container no-gutters position-relative"
           v-if="doctors.length"
           id="d-body-container"
           :class="`range-${activeRange}`">
      <div id="time-backer" v-if="['DAY','WEEK'].includes(activeRange)"
           :class="{ scrolled: scrollLeft > 0, collapsed }" :style="{ top: timeTop+'px' }"></div>
      <b-col cols="auto"
             v-for="(d, ind) in blockDays"
             :class="{ 'hasMultipleBlocks': blockDays.length > 1 }"
             :key="`block-day-${d}`">
        <DBody :ref="`d-body-${ind}`"
               v-if="doctors.length > 0"
               :hasTimeRows="ind === 0"
               :time-over="timeOver"
               @scrollLeft="scrollLeft = $event"
               :day="d"
               @mouseleave="onMouseLeave"
               @mouseup.native="onMouseUp"
               @mousedown.native="onMouseDown"
               @mousemove.native="onMouseMove"
               @touchstart.native="onTouchDown"
               @touchend.native="onMouseUp"
               @touchmove.native="onTouchMove"
        />
      </b-col>
    </b-row>

    <transition name="fade" mode="out-in" >
      <DMenu v-if="contextMenuVisible"
             v-click-outside="clickedOutside"
             :contextMenuHeight="contextMenuHeight"
             :record="contextRecord"
             :cord-x="contextMenuCordsX"
             :cord-y="contextMenuCordsY"/>
    </transition>

    <AddNewVisitModal/>
    <VisitPayModal/>
    <PatientSMSModal/>
    <SendMailModal/>
    <DCalendarSettingsModal />
    <DoctorDayEditModal/>
    <ReasonPrompt ref="reasonPrompt"/>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import CalendarNavigator from "../../parts/calendar/CalendarNavigator"
import CalendarNSvg from '@/assets/svg-vue/calendar/add_white.svg'
import DBody from "@/components/pages/calendar/calendar/DBody";
import DRangeSwitcher from "@/components/pages/calendar/calendar/DRangeSwitcher";
import DViewSwitcher from "@/components/pages/calendar/calendar/DViewSwitcher";
import DDoctors from "@/components/pages/calendar/calendar/DDoctors";
import moment from "moment/moment"
import DMenu from "@/components/pages/calendar/calendar/DMenu";
import DCalendarSettings from "@/components/pages/calendar/calendar/DCalendarSettings";
import DCalendarSettingsModal from "@/components/pages/calendar/calendar/DCalendarSettingsModal";
import AddNewVisitModal from "@/components/parts/calendar/AddNewVisitModal"
import VisitPayModal from "@/components/parts/patients/VisitPayModal"
import PatientSMSModal from "@/components/parts/patients/PatientSMSModal"
import SendMailModal from "@/components/parts/patients/SendMailModal"
import { EloquentService } from "@/services/api.service"
import CalendarToday from "@/components/parts/calendar/CalendarToday"
import DDaysSwitcher from "@/components/pages/calendar/calendar/DDaysSwitcher"
import DProfessionSwitcher from "@/components/pages/calendar/calendar/DProfessionSwitcher"
import DoctorDayEditModal from "@/components/parts/staff/DoctorDayEditModal"
import CalendarViewSwitch from "@/components/parts/calendar/CalendarViewSwitch.vue"
import ReasonPrompt from "@/components/parts/general/ReasonPrompt.vue"

export default {
  name: "Calendar",
  head() {
    return {
      title: this.$t('calendar'),
    }
  },
  components: {
    ReasonPrompt,
    CalendarViewSwitch,
    DoctorDayEditModal,
    DProfessionSwitcher,
    DDaysSwitcher,
    CalendarToday,
    SendMailModal,
    PatientSMSModal,
    VisitPayModal,
    AddNewVisitModal,
    DCalendarSettings,
    DMenu,
    DDoctors,
    DViewSwitcher,
    DRangeSwitcher,
    DBody,
    CalendarNavigator,
    CalendarNSvg,
    DCalendarSettingsModal,
  },
  data() {
    return {
      clickData: null,
      // currentDoctor: [],
      loaded: false,
      loadingRecords: false,
      canDrag: false,
      canResize: false,
      isDrag: false,
      isResize: false,
      handledComponent: null,
      handledMouseRecord: null,
      hoveredTimeRange: [],
      contextMenuOutsideClickable: true,
      contextMenuCordsX: 0,
      contextMenuCordsY: 0,
      contextMenuWidth: 195,
      contextMenuHeight: 380,
      contextRecord: null,
      initialPointX: null,
      initialPointY: null,
      tempRecordDate: null,
      tempRecordDoctor: null,
      tempRecordChair: null,
      initialRecordDate: null,
      initialRecordStart: null,
      initialRecordEnd: null,
      initialRecordDoctor: null,
      initialRecordChair: null,
      tempForTesting: null,
      touchTimer: null,
      resetMovableListener: null,
      daysSwitcherEnabled: true,
      scrollLeft: 0,
      timeTop: 118,
      intervaller: null,
      refreshTimer: null,
    }
  },
  computed: {
    ...mapState({
      records: state => state.calendar.records,
      isLoading: state => state.calendar.isLoading,
      clinic: state => state.auth.clinic,
      collapsed: state => state.dom.sidebarCollapsed,
      doctors: state => state.calendar.doctors,
      events: state => state.calendar.events,
      selectedDoctor: state => state.calendar.selectedDoctor,
      selectedRecord: state => state.calendar.selectedRecord,
      activeDate: state => state.calendar.activeDate,
      today: state => state.calendar.today,
      calendarConfig: state => state.calendar.calendarConfig,
      curView: state => state.calendar.curView,
      calendarDays: state => state.calendar.calendarDays,
      viewDates: state => state.calendar.viewDates,
      resizableRecord: state => state.calendar.resizableRecord,
      resizableComponent: state => state.calendar.resizableComponent,
      timeHeight: state => state.calendar.timeHeight,
      timeResizeMinStep: state => state.calendar.timeResizeMinStep,
      contextMenuVisible: state => state.calendar.contextMenuVisible,
      justClosedContext: state => state.calendar.justClosedContext,
      showOverflowScreen: state => state.calendar.showOverflowScreen,
      refreshCalendar: state => state.calendar.refreshCalendar,
      branch_id: state => state.auth.branch,
      rights: state => state.auth.rights,
      counter: state => state.datatable.dataTableCounter,
      leadForRecord: state => state.calendar.leadForRecord,
      stickyCalendar: state => state.calendar.stickyCalendar,
      scheduleView: state => state.calendar.scheduleView,
      isMobile: state => state.dom.isMobile,
    }),
    ...mapGetters({
      timeStep: 'employeeTimeStep',
      activeRange: 'activeRange'
    }),
    additional() {
      return this.clinic.clinic_additional
    },
    timeMinPx: function() {
      return this.timeHeight / this.timeStep
    },
    timeStepPx: function() {
      return this.timeMinPx * this.timeResizeMinStep
    },
    selectedCursor: function() {
      if (this.isDrag === true) return 'move'
      if (this.isResize === true) return 'ns-resize'
      return 'auto'
    },
    blockDays() {
      return this.blockDates()
    },
  },
  watch: {
    viewDates() {
      console.log('view dates changed')
      this.requestData()
    },
    calendarDays() {
      console.log('calendar days changed')
      this.requestData()
    },
    curView() {
      console.log('cur view changed')
      this.requestData()
    },
    counter() {
      this.$store.commit('setContextMenuVisible', false)
      this.requestData()
    },
    isDrag(val) {
      this.$store.commit('setIsDrag', val)
    },
    refreshCalendar(val) {
      if(val) {
        if(this.refreshTimer) clearTimeout(this.refreshTimer)
        this.refreshTimer = setTimeout(() => {
          this.requestData(false)
        }, 1500)
      }
      // if(val && !this.tooEarlyToRefresh()) this.requestData(false)
    },
  },
  async created() {
    window.addEventListener('scroll', this.handleScroll)
    this.resetMovableListener = event => {
      if (event.key === 'Escape') {
        this.$store.commit('setRecordToMove', null)
        this.$store.commit('setRecordToCopy', null)
      }
    }
    window.addEventListener('keyup', this.resetMovableListener)
    await this.getDoctors(this.branch_id)
    await this.requestData()
    this.intervaller = setInterval(() => this.requestData(false), (1000 * 60 * 10))
    if(this.leadForRecord?.record?.id) {
      let chair = null
      if(!this.leadForRecord.record.chair_id) {
        chair = this.$store.getters.getChairForRecord(this.leadForRecord.record)
      }
      this.$store.commit('setNewVisit', {
        id: this.leadForRecord.record_id,
        client_id: this.leadForRecord.patient,
        chair_id: chair?.id || null,
        chair,
        source_id: this.leadForRecord?.source_id
      })
      this.$bvModal.show('add-new-visit-modal')
    }
  },
  methods: {
    ...mapGetters([
      'getRequestParams',
      'blockDates',
    ]),
    ...mapActions([
      'getDoctors',
      'getRecords',
      'recountRecordsByDay',
      'getStaffSchedule'
    ]),
    recordHasCrossedRecordsForResize(record, doctorId, newEnd) {
      if (typeof this.records == "undefined" || !this.records.length) {
        return false
      }
      const arr = this.records
          .filter(item => item.doctor_id === doctorId && item.id !== record.id)
          .filter(item => newEnd > item.startMoment)
      return arr.length
    },
    recordHasCrossedRecordsForDrag(recordId, doctorId, newStart, newEnd) {
      if (typeof this.records == "undefined" || !this.records.length) {
        return false
      }
      const arr = this.records
          .filter(item => item.doctor_id === doctorId && item.id !== recordId)
          .filter(item => (newStart < item.endMoment && newEnd > item.startMoment))
      return arr.length
    },
    addVisit() {
      let now = moment().format('YYYY-MM-DD HH')+":30:00"
      if(moment().format('mm') > 30) {
        now = moment().add(1, 'hours').format('YYYY-MM-DD HH')+":00:00"
      }
      this.$store.commit('setNewVisit', {
        time: now,
      })
      this.$bvModal.show('add-new-visit-modal')
    },
    async requestData(loader = true) {
      if(this.loadingRecords) return
      this.loadingRecords = true
      // getting doctor list and get records
      if(this.scheduleView === 'doctors') {
        const params = this.getRequestParams()
        params.loader = loader
        await this.getRecords(params)
      } else {
        await this.getStaffSchedule({})
      }
      this.handleScroll()
      this.loadingRecords = false
    },
    onTouchDown(e) {
      let event = e.touches[0]
      if (this.resizableRecord) {
        this.handledMouseRecord = this.resizableRecord
      }
      this.touchTimer = setTimeout(() => {
        this.onMouseDown(event)
      }, 305)
    },
    onMouseDown(event) {
      if(this.contextMenuVisible) {
        this.$store.commit('setJustClosedContext', true)
        setTimeout(() => {
          this.$store.commit('setJustClosedContext', false)
        }, 200)
      }
      this.$store.commit('setContextMenuVisible', false)
      if (this.resizableRecord) {
        this.initialPointX = event.pageX
        this.initialPointY = event.pageY
        this.initialRecordStartFull = this.resizableRecord.start
        this.initialRecordEndFull = this.resizableRecord.end
        this.initialRecordStart = this.resizableRecord.startMoment.format('HH:mm:ss')
        this.initialRecordEnd = this.resizableRecord.endMoment.format('HH:mm:ss')
        this.tempRecordDate = this.resizableRecord.startMoment.format('YYYY-MM-DD')
        this.tempRecordDoctor = this.resizableRecord.doctor_id
        this.tempRecordChair = this.resizableRecord.chair_id
        this.initialRecordDate = this.resizableRecord.startMoment.format('YYYY-MM-DD')
        this.initialRecordDoctor = this.resizableRecord.doctor_id
        this.initialRecordChair = this.resizableRecord.chair_id
        this.handledMouseRecord = this.resizableRecord
        this.handledComponent = this.resizableComponent
        if(!this.rights.includes('add_records')) return
        if(this.resizableRecord.hasPassed && !this.rights.includes('edit_history')) {
          if(!(this.resizableRecord.startMoment.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD') && this.rights.includes('records_during_day'))) {
            return
          }
        }
        if (this.handledMouseRecord.isOnResize === true) {
          this.canResize = true
        } else {
          this.canDrag = true
        }
      }
    },
    onMouseUp() {
      if(this.touchTimer) {
        clearTimeout(this.touchTimer)
      }
      if (this.handledMouseRecord) {
        if (this.isDrag) {
          this.onSaveDragResult(this.handledMouseRecord)
        } else if (this.isResize) {
          this.onSaveResizeResult(this.handledMouseRecord)
        } else {
          this.onShowRecordDetails(this.handledMouseRecord)
        }
      }
      this.dropValues(true)
    },
    onMouseLeave() {
      if (!this.isResize && !this.isDrag) this.dropValues(true)
    },
    onMouseMove(e) {
      if (this.handledMouseRecord) {
        if (this.canResize) this.isResize = true
        else if (this.canDrag) this.isDrag = true

        if (this.isResize) this.doResize(this.handledMouseRecord, e)
        else if (this.isDrag) this.doDrag(this.handledMouseRecord, e)
      }
    },
    onTouchMove(e) {
      if(this.touchTimer) {
        clearTimeout(this.touchTimer)
      }
      let event = e.touches[0]
      this.onMouseMove(event)
      if(!this.isDrag && !this.isResize && this.handledMouseRecord) {
        this.handledMouseRecord = null
      }
    },
    doResize(record, e) {
      this.listenYMovesResize(record, e)
    },
    doDrag(record, e) {
      this.listenYMoves(record, e)
      this.listenXMoves(record, e)
    },
    moveHasChanges(record) {
      return this.initialRecordStartFull !== record.start
          || this.initialRecordEndFull !== record.end
          || this.initialRecordDoctor !== record.doctor_id
          || this.initialRecordChair !== record.chair_id
    },
    async onSaveDragResult(record) {
      // console.log('saveRecord')
      if(!this.moveHasChanges(record)) {
        this.onShowRecordDetails(this.handledMouseRecord)
        return
      }
      if (this.moveHasChanges(record) && window.confirm(this.$t('are_you_sure_want_to_move_visit'))) {
        let reason = ''
        if(this.additional.ask_reason_reschedule) {
          reason = await this.$refs.reasonPrompt.showPrompt()
        }
        if(reason || !this.additional.ask_reason_reschedule) {
          this.saveChangedDates(record, reason)
          return
        }
      }
      this.restoreInitialRecord(record)
      this.dropValues(true)
    },
    onSaveResizeResult(record) {
      if (this.moveHasChanges(record) && window.confirm(this.$t('are_you_sure_want_to_change_time'))) {
        // console.log('saveResizeResult', record.id)
        this.saveChangedDates(record)
      } else {
        this.restoreInitialRecord(record)
        this.dropValues(true)
      }
    },
    saveChangedDates(record, reason = null) {
      let formData = new FormData()
      formData.append('start', record.start)
      formData.append('end', record.end)
      formData.append('reason', reason)
      if(record.doctor_id && record.doctor_id != 'null') {
        formData.append('doctor_id', record.doctor_id)
      }
      if(record.chair_id && record.chair_id != 'null') {
        formData.append('chair_id', record.chair_id)
      }
      EloquentService.update('record', record.id, formData).then(() => {
        this.initialRecordDate = null
        this.initialRecordStart = null
        this.initialRecordEnd = null
        this.initialRecordDoctor = null
        this.initialRecordChair = null
        this.$noty.info(this.$t('success_message'))
        this.recountRecordsByDay()
        // this.$store.commit('recountRecordsByDay')
      }).catch(() => {
        this.restoreInitialRecord(record)
        this.dropValues(true)
      })
    },
    restoreInitialRecord(record) {
      const initStart = moment(`${this.initialRecordDate} ${this.initialRecordStart}`)
      const initEnd = moment(`${this.initialRecordDate} ${this.initialRecordEnd}`)
      record.start = initStart.format('YYYY-MM-DD HH:mm:ss')
      record.end = initEnd.format('YYYY-MM-DD HH:mm:ss')
      record.startMoment = initStart
      record.endMoment = initEnd
      record.doctor_id = this.initialRecordDoctor
      record.chair_id = this.initialRecordChair
      this.$store.commit('replaceRecord', record)
    },
    onShowRecordDetails(record) {
      if(this.justClosedContext && this.contextRecord && this.contextRecord.id == record.id) {
        return
      }
      // const selectedCol = this.findSelectedCol(record, bodyIndex)
      // console.log(selectedCol)
      // if(!selectedCol) return
      // const domRect = selectedCol.$el.getBoundingClientRect()
      const domRect = this.findSelectedCol(record)
      this.contextRecord = record
      this.contextMenuCordsX = this.defineX(domRect)
      this.contextMenuCordsY = this.defineY(domRect)
      this.$store.commit('setContextMenuVisible', true)
      this.$store.commit('setRecordToMove', null)
      this.$store.commit('setRecordToCopy', null)
      this.contextMenuOutsideClickable = false
      setTimeout(() => {
        this.contextMenuOutsideClickable = true
      },10)
    },
    listenYMovesResize(record, e) {
      const changesY = e.pageY - this.initialPointY
      const index = Math.ceil(changesY / this.timeStepPx)
      let time = index * this.timeResizeMinStep
      const duration = (moment.duration(moment(record.end).diff(moment(record.start)))).asMinutes()
      if(duration >= 5) { // min 5 minutes visit
        this.checkNewValueAndSetOnResize(record, time)
      }
    },
    checkNewValueAndSetOnResize(record, time) {
      const newStart = this.initialRecordStart.startMoment
      const newEnd = this.makeMoment(this.initialRecordEnd).add(time, 'minutes')
      if (!this.recordHasCrossedRecordsForDrag(record.id, record.doctor_id, newStart, newEnd)) {
        record.end = newEnd.format('YYYY-MM-DD HH:mm:ss')
        record.endMoment = newEnd
        this.$store.commit('replaceRecord', record)
      }
    },
    listenYMoves(record, e) {
      const changesY = e.pageY - this.initialPointY
      const index = Math.ceil(changesY / this.timeStepPx)
      const time = index * this.timeResizeMinStep
      this.checkNewValueAndSetOnDrag(record, time)
    },
    listenXMoves(record, e) {
      const el = this.defineStepComponentUnderMousePointer(e.pageX, e.pageY)
      if(!el || typeof el == "undefined") return
      const moveTsString = `${el.getAttribute('data-date')}`
      const moveDoctorString = `${el.getAttribute('data-doctor')}`
      const moveChairString = `${el.getAttribute('data-chair')}`
      if (moveTsString !== this.tempRecordDate
          || moveDoctorString !== this.tempRecordDoctor
          || moveChairString !== this.tempRecordChair) {
        this.tempRecordDate = moveTsString == 'null' ? null : moveTsString
        this.tempRecordDoctor = moveDoctorString == 'null' ? null : moveDoctorString
        this.tempRecordChair = moveChairString == 'null' ? null : moveChairString
        this.recordHasCrossedRecordsForResize(record, moveDoctorString ? moveDoctorString : 0)
      }
    },
    checkNewValueAndSetOnDrag(record, time) {
      const newStart = this.makeMoment(this.initialRecordStart).add(time, 'minutes')
      const newEnd = this.makeMoment(this.initialRecordEnd).add(time, 'minutes')
      if (!this.recordHasCrossedRecordsForDrag(record.id, record.doctor_id, newStart, newEnd)) {
        record.start = newStart.format('YYYY-MM-DD HH:mm:ss')
        record.end = newEnd.format('YYYY-MM-DD HH:mm:ss')
        record.startMoment = newStart
        record.endMoment = newEnd
        let interrupt = false
        if(this.isMobile) {
          if(this.tempRecordDoctor && this.tempRecordDoctor != record.doctor_id) interrupt = true
          if(this.tempRecordChair && this.tempRecordChair != record.chair_id) interrupt = true
        }
        if(this.tempRecordDoctor) record.doctor_id = this.tempRecordDoctor
        if(this.tempRecordChair) record.chair_id = this.tempRecordChair
        this.$store.commit('replaceRecord', record)
        if(interrupt) this.onMouseUp()
      } else {
        if(this.tempRecordDoctor) record.doctor_id = this.tempRecordDoctor
        if(this.tempRecordChair) record.chair_id = this.tempRecordChair
        this.$store.commit('replaceRecord', record)
      }
    },
    makeMoment(tms) {
      return moment(`${this.tempRecordDate} ${tms}`)
    },
    defineX(domRect) {
      let x = domRect.x < 0 ? 0 : (domRect.x)
      if (domRect.x > (window.innerWidth - this.contextMenuWidth)) {
        x = window.innerWidth - this.contextMenuWidth
      } else {
        x = domRect.x + domRect.width - this.contextMenuWidth
      }
      const minX = document.getElementById('calendar').getBoundingClientRect().left + window.scrollX + 50
      return Math.max(minX, x)
    },
    defineY(domRect) {
      let y = domRect.y < 0 ? 0 : (domRect.y)
      if (y > (window.innerHeight - this.contextMenuHeight)) {
        y = window.innerHeight - this.contextMenuHeight
      }
      return y
    },
    findSelectedCol(record) {
      const el = document.getElementById(`record-block-${record.id}`)
      return el?.getBoundingClientRect() || null
    },
    dropValues(resetInitialRecord = false) {
      this.canDrag = false
      this.canResize = false
      this.isDrag = false
      this.isResize = false
      this.initialPointX = null
      this.initialPointY = null
      this.tempRecordDate = null
      this.tempRecordDoctor = null
      this.tempRecordChair = null
      if(resetInitialRecord) {
        this.handledMouseRecord = null
      }
      this.$store.commit('nullifyResizableRecord')
    },
    timeOver(bool, time) {
      if (this.handledMouseRecord) {
        const recordStart = moment(this.handledMouseRecord.start)
        const timeStr = recordStart.format('YYYY-MM-DD') + ' ' + time + ':00'
        const newTime = moment(timeStr).add(+this.timeStep, 'm')
        if (newTime.isAfter(recordStart)) {
          this.handledMouseRecord.end = newTime.format('YYYY-MM-DD HH:mm:ss')
          this.$store.commit('replaceRecord', this.handledMouseRecord)
        }
      }
    },
    handleScroll() {
      let calendars = document.getElementsByClassName('d-calendar')
      if(!calendars.length) return
      this.timeTop = Math.max(0, calendars[0].getBoundingClientRect().top)
    },
    defineStepComponentUnderMousePointer(x,y) {
      const els = document.elementsFromPoint(x - window.pageXOffset,y - window.pageYOffset).filter(item => item.classList.contains('d-calendar__step'));
      return Array.isArray(els) && els.length ? els[0] : null
    },
    clickedOutside() {
      if(this.contextMenuOutsideClickable) {
        this.$store.commit('setJustClosedContext', true)
        this.$store.commit('setContextMenuVisible', false)
        setTimeout(() => {
          this.$store.commit('setJustClosedContext', false)
        }, 50)
      }
    },
    resetMovableRecord() {

    }
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('keyup', this.resetMovableListener)
    this.$store.commit('setRecords', [])
    this.$store.commit('setPatientFromCall', null)
    this.$store.commit('setLeadForRecord', null)
    this.$store.commit('setPhoneFromCall', null)
    // if(this.intervaller) clearInterval(this.intervaller)
  },
  mounted() {
    this.handleScroll()
  }
}
</script>

<style scoped lang="scss">
.d-body-container {
  padding-left: 40px;
  flex-wrap: nowrap;
  overflow-x: auto;
  &.range-MONTH {
    padding-left: 0;
  }
  #time-backer {
    background: white;
    position: fixed;
    width: 65px;
    margin-left: 142px;
    transition: margin-left 0.25s ease-out;
    height: 100%;
    z-index: 7;
    left: 97px;
    &.collapsed {
      margin-left: 0;
    }
    &.scrolled {
      border-right: 1px solid #B5C6D8;
    }

  }
}
.hasMultipleBlocks {
  max-width: none;
  width: fit-content;
}

.loading-block {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(250, 250, 250, 0.7)
}
#calendar-top-menu {
  top: 0;
  position: sticky;
  z-index: 8;
  background: white;
}

@media screen and (max-width: 768px) {
  .d-body-container {
    padding-left: 30px;
    #time-backer {
      margin-left: 0;
      left: 0;
      width: 45px;
    }
  }
}

</style>
