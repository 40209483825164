<template>
<svg class="feather feather-move" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
     stroke-width="2"
     viewBox="0 0 24 24"
     height="12"
     width="12"
     xmlns="http://www.w3.org/2000/svg">
  <polyline points="5 9 2 12 5 15" stroke="#B5C6D8"/>
  <polyline points="9 5 12 2 15 5" stroke="#B5C6D8"/>
  <polyline points="15 19 12 22 9 19" stroke="#B5C6D8"/>
  <polyline points="19 9 22 12 19 15" stroke="#B5C6D8"/>
  <line x1="2" x2="22" y1="12" y2="12" stroke="#B5C6D8"/>
  <line x1="12" x2="12" y1="2" y2="22" stroke="#B5C6D8"/>
</svg>
</template>
