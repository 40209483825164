<template>
  <div class="clinic-marketing-data page pb-5 pt-4 form-narrow">
    <b-button v-spaceduck-collapse="`settings-c`"
              class="btn-collapse">
      {{ $t('settings') }}
    </b-button>
    <spaceduck-collapse :visible="true"
                        id="settings-c">
      <div class="pt-4">
        <CheckboxGroup :label="$t('create_application_on_call')"
                       :checkboxvalue="1"
                       fid="create_application_on_call"
                       v-model="create_application_on_call"/>
        <div v-if="create_application_on_call" class="ml-5">
          <CheckboxGroup :label="$t('for_incoming')"
                         :checkboxvalue="1"
                         class="mt-3"
                         fid="application_on_incoming_call"
                         v-model="application_on_incoming_call"/>
          <CheckboxGroup :label="$t('for_outcoming')"
                         :checkboxvalue="1"
                         class="mt-3"
                         fid="application_on_outcoming_call"
                         v-model="application_on_outcoming_call"/>
          <CheckboxGroup :label="$t('for_missed')"
                         :checkboxvalue="1"
                         class="mt-3"
                         fid="application_on_missed_call"
                         v-model="application_on_missed_call"/>
        </div>
        <CheckboxGroup :label="$t('create_lead_on_incoming_messages')"
                       class="mt-4"
                       :checkboxvalue="1"
                       fid="create_lead_on_messages"
                       v-model="create_lead_on_messages"/>
        <CheckboxGroup :label="$t('do_not_create_duplicates_on_call')"
                       class="mt-4"
                       :checkboxvalue="1"
                       fid="avoid_call_duplicates"
                       v-model="avoid_call_duplicates"/>
        <div class="mt-4">
          <RadioGroup :options="leadCallResponsibleOptions"
                      :label="$t('responsible_on_call')"
                      :showlabel="true"
                      v-model="callResponsible"/>
          <ServiceMultiSelect :label="$t('User')"
                              v-if="callResponsible === 'user_id' && initialLoaded"
                              :dblclick="false"
                              model="staff"
                              :multiple="false"
                              title="full_name"
                              :prepopulated-options="staff"
                              :dbsearch="false"
                              v-model="callResponsibleId"/>
        </div>
        <div class="mt-4">
          <RadioGroup :options="leadResponsibleOptions"
                      :label="$t('responsible_on_other_leads_not_created_manually')"
                      :showlabel="true"
                      v-model="leadResponsible"/>
          <ServiceMultiSelect :label="$t('User')"
                              v-if="leadResponsible === 'user_id' && initialLoaded"
                              :only-id="true"
                              :dblclick="false"
                              model="staff"
                              :multiple="false"
                              title="full_name"
                              :prepopulated-options="staff"
                              :dbsearch="false"
                              v-model="leadResponsibleId"/>
        </div>
        <div class="mt-4">
          <ServiceMultiSelect :label="$t('if_lead_has_no_responsible_then_assign_to_this_person_on_first_save')"
                              :dblclick="false"
                              v-if="initialLoaded"
                              :only-id="true"
                              model="staff"
                              :multiple="false"
                              title="full_name"
                              :prepopulated-options="staff"
                              :dbsearch="false"
                              v-model="manualResponsibleId"/>
        </div>
        <div class="mt-5 text-right">
          <button class="btn btn-themed btn-themed-squared" @click="save">{{ $t('save') }}</button>
        </div>
      </div>
    </spaceduck-collapse>

    <div class="mt-4">
      <b-button v-spaceduck-collapse="`stages-c`"
                class="btn-collapse">
        {{ $t('stages') }}
      </b-button>
      <spaceduck-collapse id="stages-c">
        <CRMStages/>
      </spaceduck-collapse>
    </div>

  </div>
</template>

<script setup>
import { useHead } from "@unhead/vue"
import { useStore } from "vuex"
import { computed, getCurrentInstance, onBeforeMount, onMounted, ref } from "vue"
import { ClinicService, EloquentService } from "@/services/api.service"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"
import CRMStages from "@/components/pages/crm/CRMStages.vue"
import RadioGroup from "@/components/form/RadioGroup.vue"
import { leadResponsibleOptions, leadCallResponsibleOptions } from "@/dictionaries/dictionary"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect.vue"

const store = useStore()
let i18n = null
let noty = null
const title = ref('')
const callResponsible = ref('nobody')
const callResponsibleId = ref(null)
const leadResponsible = ref('nobody')
const leadResponsibleId = ref(null)
const manualResponsibleId = ref(null)
const staff = ref([])
const initialLoaded = ref(false)

useHead({
  title: () => title.value,
})

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = i18n.t('settings')
})

const create_application_on_call = ref(0)
const create_lead_on_messages = ref(0)
const avoid_call_duplicates = ref(0)
const application_on_incoming_call = ref(0)
const application_on_outcoming_call = ref(0)
const application_on_missed_call = ref(0)
const clinic = computed(() => store.state.auth.clinic)
const branch = computed(() => store.state.auth.branch)
const superadmin = computed(() => store.state.auth.superadmin)
const ppoSettings = computed(() => {
  return clinic.value.ppo_settings ? JSON.parse(clinic.value.ppo_settings) : {
    create_application_on_call: 0,
    create_lead_on_messages: 0,
    avoid_call_duplicates: 0,
    application_on_incoming_call: 0,
    application_on_outcoming_call: 0,
    application_on_missed_call: 0,
    call_responsible: 'admins',
    call_responsible_id: null,
    lead_responsible: 'nobody',
    lead_responsible_id: null,
    manual_responsible_id: null,
  }
})

onMounted(() => {
  create_application_on_call.value = ppoSettings.value.create_application_on_call
  create_lead_on_messages.value = ppoSettings.value.create_lead_on_messages
  application_on_incoming_call.value = ppoSettings.value.application_on_incoming_call
  application_on_outcoming_call.value = ppoSettings.value.application_on_outcoming_call
  application_on_missed_call.value = ppoSettings.value.application_on_missed_call
  avoid_call_duplicates.value = ppoSettings.value.avoid_call_duplicates
  noty = getCurrentInstance()?.appContext?.app?.noty
  callResponsible.value = ppoSettings.value.call_responsible || 'admins'
  callResponsibleId.value = ppoSettings.value.call_responsible_id || null
  leadResponsible.value = ppoSettings.value.lead_responsible || 'nobody'
  leadResponsibleId.value = ppoSettings.value.lead_responsible_id || null
  manualResponsibleId.value = ppoSettings.value.manual_responsible_id || null
  loadStaff()
})

const loadStaff = async () => {
  const res = await EloquentService.autocomplete('staff',
      '',
      1000,
      true,
      superadmin.value,
      superadmin.value && clinic.value ? clinic.value.id : null,
      branch.value
  )
  staff.value = res.data.result
  initialLoaded.value = true
}

const save = async () => {
  await ClinicService.savePPOAttribute([
    'create_application_on_call',
    'create_lead_on_messages',
    'avoid_call_duplicates',
    'application_on_incoming_call',
    'application_on_outcoming_call',
    'application_on_missed_call',
    'call_responsible',
    'call_responsible_id',
    'lead_responsible',
    'lead_responsible_id',
    'manual_responsible_id'
  ], [
    create_application_on_call.value,
    create_lead_on_messages.value,
    avoid_call_duplicates.value,
    application_on_incoming_call.value,
    application_on_outcoming_call.value,
    application_on_missed_call.value,
    callResponsible.value,
    callResponsible.value === 'user_id' ? callResponsibleId.value : null,
    leadResponsible.value,
    leadResponsible.value === 'user_id' ? leadResponsibleId.value : null,
    manualResponsibleId.value,
  ], superadmin.value, clinic.value.id)
  noty.info(i18n.t('success_message'))
  await store.dispatch('init')
}
</script>
