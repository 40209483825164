export default {
    tag_ADDRESS: "Client's address",
    tag_ADDRESS_PREDSTAVITEL: "Client's representative address",
    tag_BIRTHDAY: "Client's birthday",
    tag_BIRTHDAY_PREDSTAVITEL: "Client's representative birthday",
    tag_BRANCH_ADDRESS: "Branch address",
    tag_BRANCH_BIN: "Branch BIN",
    tag_BRANCH_INN: "Branch INN",
    tag_BRANCH_KPP: "Branch KPP",
    tag_BRANCH_LICENSE: "Branch license",
    tag_BRANCH_OGRN: "Branch OGRN",
    tag_CARD_CLIENT: "Client's card number",
    tag_CLIENT: "Client's full name",
    tag_CLIENT_BONUSES: "Client's bonuses",
    tag_CLIENT_NAME: "Client's name",
    tag_CLINIC: "Clinic name",
    tag_COMPLEX_STATUS: "Treatment plan complex status",
    tag_COST_DISCOUNTED: "Cost of services with discount",
    tag_COST_ORIGINAL: "Cost of services without discount",
    tag_DATA: "Date of creating the document",
    tag_DATA_VIZIT: "Date of visit",
    tag_DATE: "Date of creating Mailing",
    tag_DATE_FIRST_VIZIT: "First visit date",
    tag_DIAGNOZ: "Diagnosis",
    tag_DIAGNOZ_PLAN: "Treatment plan Diagnosis",
    tag_DOCTOR: "Doctor's full name",
    tag_DOCUMENT_TYPE_PREDSTAVITEL: "Client's representative document type",
    tag_FIO_CLIENT: "Client's full name",
    tag_FIO_DIRECTOR: "Director's full name",
    tag_IIN: "Client's IIN",
    tag_INN: "Client's INN",
    tag_LEGAL_ADDRESS: "Clinic legal address",
    tag_LEGAL_COMPANY_NAME: "Clinic legal name",
    tag_PASSPORT: "Client's passport number",
    tag_PASSPORT_CODE: "Client's passport department code",
    tag_PASSPORT_CODE_PREDSTAVITEL: "Client's representative passport department code",
    tag_PASSPORT_DATE: "Client's passport issue date",
    tag_PASSPORT_DATE_PREDSTAVITEL: "Client's representative document issue date",
    tag_PASSPORT_ISSUED: "Client's passport issued by",
    tag_PASSPORT_ISSUED_PREDSTAVITEL: "Client's representative document issued by",
    tag_PASSPORT_PREDSTAVITEL: "Client's representative passport number",
    tag_PASSPORT_SERIES: "Client's passport series",
    tag_PASSPORT_SERIES_PREDSTAVITEL: "Client's representative document series",
    tag_PHONE: "Client's phone",
    tag_PHONE_CLIENT: "Client's phone",
    tag_PHONE_PREDSTAVITEL: "Client's representative phone",
    tag_PREDSTAVITEL_PACIENTA: "Client's representative full name",
    tag_PLAN_STATUS: "Treatment plan status",
    tag_PLAN_TITLE: "Treatment plan title",
    tag_SNILS: "Client's SNILS",
    tag_SNILS_PREDSTAVITEL: "Client's representative SNILS",
    tag_TASK: "Task text",
    tag_URL_VIZIT_CONFIRMATION: "URL for visit confirmation",
    tag_URL_VIZIT_REVIEW: "URL for visit feedback",
    tag_USLUGI: "Services",
    tag_USLUGI_PLAN: "Treatment plan services",
    tag_VISIT_DOCTOR: "Visit's doctor's full name",
}
