<template>
  <aside class="sidebar" :class="{ collapsed: collapsed, hasBitrix: hasBitrix }">
    <router-link :to="{ name: 'Calendar' }" class="d-block sidebar__logo-wrapper">
      <img src="/images/general/logo_short.png" class="sidebar__logo sidebar__logo-short"/>
      <img src="/images/general/logo.png" class="sidebar__logo sidebar__logo-full"/>
    </router-link>
    <MenuToggler view="sidebar"/>
    <div class="sidebar__menu">
      <template v-for="(m, mind) in topmenu">
        <div v-if="(!m.right || (m.right && rights.includes(m.right))) && (!m.rightNot || !rights.includes(m.rightNot))"
             :key="`topmenu-${mind}`"
             class="side-menu-item">
          <a v-if="m.rightPopup && !rights.includes(m.rightPopup) && hasFreeTariff"
             class="sidebar__item"
             @click="$bvModal.show('module-not-in-tariff-modal')">
            <component :is="m.i"
                       :class="`sidebar__svg-${m.title}`"/>
            <span class="sidebar__title">{{ $t(m.title) }}</span>
          </a>
          <router-link v-else-if="!m.rightPopup || rights.includes(m.rightPopup)"
                       class="sidebar__item"
                       :class="m.class"
                       :to="m.to">
            <component :is="m.i" :class="`sidebar__svg-${m.title}`"/>
            <span class="sidebar__title">{{ $t(m.title) }}</span>
          </router-link>
        </div>
      </template>
      <div class="sidebar__separator" v-if="hasSettingsPart">
        <span>{{ $t('control') }}</span>
      </div>
      <template v-for="(m, mind) in bottommenu">
        <div v-if="!m.right || (m.right && rights.includes(m.right))"
             :key="`bottommenu-${mind}`" >
          <a v-if="m.rightPopup && !rights.includes(m.rightPopup) && hasFreeTariff"
             class="sidebar__item"
             @click="$bvModal.show('module-not-in-tariff-modal')">
            <component :is="m.i"/>
            <span class="sidebar__title">{{ $t(m.title) }}</span>
          </a>
          <router-link v-else-if="!m.rightPopup || rights.includes(m.rightPopup)"
                       class="sidebar__item"
                       :to="m.to">
            <component :is="m.i"/>
            <span class="sidebar__title">{{ $t(m.title) }}</span>
          </router-link>
        </div>
      </template>
    </div>
  </aside>
</template>

<script>

import BoxSvg from '@/assets/svg-vue/sidebar/box.svg'
import CalculatorSvg from '@/assets/svg-vue/sidebar/calculator.svg'
import CalendarSvg from '@/assets/svg-vue/sidebar/calendar.svg'
import DoctorSvg from '@/assets/svg-vue/sidebar/doctor.svg'
import FolderSvg from '@/assets/svg-vue/sidebar/folder.svg'
import PaperSvg from '@/assets/svg-vue/sidebar/paper.svg'
import PatientsSvg from '@/assets/svg-vue/sidebar/patients.svg'
import SettingsSvg from '@/assets/svg-vue/sidebar/settings.svg'
import TemplatesSvg from '@/assets/svg-vue/sidebar/template.svg'
import CashdeskSvg from '@/assets/svg-vue/sidebar/cashdesk.svg'
import EgiszSvg from '@/assets/svg-vue/patients/egisz.svg'
import ProcessSvg from '@/assets/svg-vue/sidebar/process.svg'
import LogSvg from '@/assets/svg-vue/sidebar/log.svg'
import ShieldSvg from '@/assets/svg-vue/sidebar/shield.svg'
import CRMSvg from '@/assets/svg-vue/sidebar/crm.svg'
import BillingSvg from '@/assets/svg-vue/sidebar/billing.svg'
import MarketingSvg from '@/assets/svg-vue/sidebar/marketing.svg'
import { mapGetters, mapState } from "vuex"
import MenuToggler from "@/components/parts/header/MenuToggler"
import { shallowRef } from "vue"

export default {
  name: "Sidebar",
  components: {
    MenuToggler,
    BoxSvg,
    CalculatorSvg,
    CalendarSvg,
    DoctorSvg,
    FolderSvg,
    PaperSvg,
    PatientsSvg,
    SettingsSvg,
    TemplatesSvg,
    ShieldSvg,
    LogSvg,
    CashdeskSvg,
    CRMSvg,
    EgiszSvg,
    ProcessSvg,
    MarketingSvg,
    BillingSvg
  },
  data() {
    return {
      topmenu: [
        { i: shallowRef(CalendarSvg), to: '/calendar', title: 'calendar' },
        { i: shallowRef(PatientsSvg), to: '/patients', title: 'patients', right: 'patients' },
        { i: shallowRef(ProcessSvg), to: '/treatment_plans', title: 'plan_treatments', right: 'planning_list' },
        { i: shallowRef(DoctorSvg), to: '/staff', title: 'staff', right: 'staff' },
        { i: shallowRef(FolderSvg), to: '/services', title: 'services', right: 'clinic_services' },
        { i: shallowRef(BoxSvg), to: '/warehouses', title: 'warehouse', rightPopup: 'materials' },
        { i: shallowRef(PaperSvg), to: '/reports', title: 'reports', rightPopup: 'reports_tab' },
        { i: shallowRef(CalculatorSvg), to: '/accounting', title: 'accounting', rightPopup: 'accounting' },
        { i: shallowRef(CalculatorSvg), to: '/expenses', title: 'expenses', rightPopup: 'enter_expenses', rightNot: 'accounting' },
        // { i: CalculatorSvg, to: '/expenses', title: 'expenses', rightPopup: 'enter_expenses' },
        { i: shallowRef(MarketingSvg), to: '/marketing', title: 'marketing', right: 'marketing' },
        { i: shallowRef(CRMSvg), to: '/crm', title: 'crm', right: 'crm' },
        { i: shallowRef(EgiszSvg), to: '/egisz/kanban', title: 'egisz', right: 'egisz_view', class: 'stroked' },
      ],
      bottommenu: [
        { i: shallowRef(SettingsSvg), to: '/settings', title: 'settings', right: 'settings' },
        { i: shallowRef(BillingSvg), to: '/billing', title: 'billing', right: 'billing' },
        { i: shallowRef(TemplatesSvg), to: '/templates', title: 'templates', right: 'treatment_templates' },
        { i: shallowRef(CashdeskSvg), to: '/cashdesk', title: 'cashdesk', right: 'cashdesk' },
        { i: shallowRef(ShieldSvg), to: '/insurance', title: 'insurance', right: 'insurance' },
        { i: shallowRef(LogSvg), to: '/logs', title: 'logs_journal', right: 'log_history' },
      ]
    }
  },
  computed: {
    ...mapState({
      collapsed: state => state.dom.sidebarCollapsed,
      rights: state => state.auth.rights,
      user: state => state.auth.user,
      clinic: state => state.auth.clinic,
    }),
    userId() {
      return this.user ? this.user.id : null
    },
    hasSettingsPart() {
      let count = 0
      this.bottommenu.forEach(item => {
        if(this.rights.includes(item.right)) count++
      })
      return count > 0
    },
    hasBitrix() {
      return this.clinic && this.clinic.support_button === "1"
    },
    ...mapGetters([
      'hasFreeTariff'
    ])
  },
  mounted() {
    if(this.user.role_id == 5) {
      this.topmenu.push({
        i: PatientsSvg,
        to: '/patients/'+this.userId,
        title: 'card',
      })
    }
  },
}
</script>

<style scoped lang="scss">
.sidebar__svg-report {
  margin-left: 1px;
}
</style>
