import authorized from "@/middleware/authorized"
import paid from '@/middleware/paid'

import CRMGeneral from "@/components/pages/crm/CRMGeneral"
import CRMTasks from "@/components/pages/crm/CRMTasks"
import CRMVisits from "@/components/pages/crm/CRMVisits"
import CRMSalesFunnel from "@/components/pages/crm/CRMSalesFunnel"
import CRMApplications from "@/components/pages/crm/CRMApplications"
import CRMSalesFunnelApplications from "@/components/pages/crm/CRMSalesFunnelApplications"
import CRMSalesFunnelPlans from "@/components/pages/crm/CRMSalesFunnelPlans"
import CRMForms from "@/components/pages/crm/CRMForms"
import CRMFeedbacks from "@/components/pages/crm/CRMFeedbacks"
import CRMSettings from "@/components/pages/crm/CRMSettings"
import CRMLeadPage from "@/components/pages/crm/CRMLeadPage"

export default {

    path: '/crm',
    meta: { middleware: [authorized, paid], layout: "default", rights: ['tasks'] },
    component: CRMGeneral,
    children: [
        { path: '', redirect: '/crm/funnel_applications' },
        {
            path: 'funnel_applications', name: "CRMSalesFunnelApplications",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['crm'] },
            component: CRMSalesFunnelApplications
        },
        {
            path: 'tasks', name: "CRMTasks",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['tasks'] },
            component: CRMTasks
        },
        {
            path: 'visits', name: "CRMVisits",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['tasks'] },
            component: CRMVisits
        },
        {
            path: 'sales', name: "CRMSalesFunnel",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['crm'] },
            component: CRMSalesFunnel
        },
        {
            path: 'funnel_plans', name: "CRMSalesFunnelPlans",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['crm'] },
            component: CRMSalesFunnelPlans
        },
        {
            path: 'applications', name: "CRMApplications",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['crm'] },
            component: CRMApplications
        },
        {
            path: 'forms', name: "CRMForms",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['crm'] },
            component: CRMForms
        },
        {
            path: 'feedbacks', name: "CRMFeedbacks",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['view_feedbacks'] },
            component: CRMFeedbacks
        },
        {
            path: 'settings', name: "CRMSettings",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['crm'] },
            component: CRMSettings
        },
        {
            path: 'lead/:id', name: "CRMLeadPage",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['crm'] },
            component: CRMLeadPage
        },
    ]
}
