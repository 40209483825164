<template>
  <b-modal id="task-show-modal"
           :title="task ? task.title : $t('task')"
           size="lg"
           @hidden="onHide"
           @show="onShow"
           centered>
    <div v-if="task">
      <div v-if="!task.onlyComments">
        <b-row class="justify-content-between">
          <b-col cols="auto" class="mb-3">
            <label class="form-label">{{ $t('type') }}</label>
            <div>{{ $t(task.task_type) }}</div>
          </b-col>
          <b-col cols="auto" class="mb-3">
            <div>
              <label class="form-label">{{ $t('importance') }}</label>
              <div>
                <CRMDatatableTDTaskType :object="task"
                                        fieldType="importance"/>
              </div>
            </div>
          </b-col>
          <b-col v-if="task.start" cols="auto" class="mb-3">
            <div>
              <label class="form-label">{{ $t('start_period') }}</label>
              <div>{{ formatDateTime(task.start) }}</div>
            </div>
          </b-col>
          <b-col cols="auto" class="mb-3">
            <div>
              <label class="form-label">{{ $t('deadline') }}</label>
              <div>{{ task.deadline ? formatDateTime(task.deadline) : '-' }}</div>
            </div>
          </b-col>
          <b-col cols="12" v-if="task.patient" class="mb-3">
            <div>
              <label class="form-label">{{ $t('patient') }}</label>
              <div class="d-flex align-items-center">
                <!--                <a @click="toPatientPage" class="text-primary pointer">{{ task.patient.full_name }}</a>-->
                <router-link :to="{ name: 'PatientVisits', params: { id: task.patient_id }}"
                             @click="$bvModal.hide('task-show-modal')"
                             class="text-primary pointer">
                  {{ task.patient.full_name }}
                </router-link>
                <span v-if="phone && !rights.includes('hide_patient_phone')"
                      class="text-primary">
                  ({{ phone }})
                </span>
                <a class="ml-3"
                   :href="`tel:${phone}`"
                   v-b-tooltip.hover
                   :title="$t('call')">
                  <PhoneSvg class="svg-primary"/>
                </a>
                <a v-if="clinic && clinic.sms_info && rights.includes('sms_send')"
                   v-b-tooltip.hover
                   :title="$t('send_sms')"
                   class="ml-3 pointer"
                   @click="smsModal(phone)">
                  <SmsSvg class="svg-primary"/>
                </a>

                <WhatsappTelegramIcon v-if="patient"
                                      :top-position="0"
                                      :patient-id="patient.id"
                                      :phone="patient.phone"/>

              </div>
            </div>
          </b-col>
        </b-row>
        <div v-if="task.lead" class="mb-3">
          <a class="pointer"
             @click="leadClicked">
            {{ $t('lead') }}#{{ task.lead_id }}
          </a>
        </div>
        <hr class="mt-0"/>
        <b-row>
          <b-col>
            <label class="form-label">{{ $t('status') }}</label>
            <div>
              <SelectGroup :options="taskStatuses"
                           v-model="task.status"
                           @input="changeStatus"
                           variant="white"
                           class="md"
                           :showlabel="false"
                           :label="$t('status')"/>
            </div>
          </b-col>
          <b-col v-if="task.user">
            <label class="form-label">{{ $t('responsible') }}</label>
            <div>{{ task.user.full_name }}</div>
          </b-col>
          <b-col v-if="task.author">
            <label class="form-label">{{ $t('author') }}</label>
            <div>{{ task.author.full_name }}</div>
          </b-col>
        </b-row>
        <div class="mt-3" v-if="task.description">
          <label class="form-label black">{{ $t('description') }}</label>
          <div>{{ task.description }}</div>
        </div>
        <div class="mt-3" v-if="task.files && task.files.length">
          <div v-for="(t, tind) in task.files" :key="`file-${tind}`">
            <a v-if="t.original" :href="t.fileUrl" target="_blank">
              {{ t.original }}
            </a>
          </div>
        </div>
      </div>
      <GeneralCommentsBlock :object="task"
                            class="mt-4"
                            model="task_comment"
                            parent-column="task_id"/>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons no-print">
        <b-button class="cancel mr-md-3 mr-2 px-3 d-inline-block align-middle w-auto"
                  variant="themed"
                  @click="edit">
          {{ $t('edit') }}
        </b-button>
        <button :disabled="loading"
                @click="close()"
                class="btn-themed align-middle btn-themed-squared">
          {{ $t('done') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"
import CRMDatatableTDTaskType from "@/components/parts/datatable/td/crm/CRMDatatableTDTaskType"
import JwtService from "@/services/jwt.service"
import GeneralCommentsBlock from "@/components/parts/general/GeneralCommentsBlock"
import {formatDateTime, formatPhone} from "@/extensions/filters/filters"
import {CalendarService, EloquentService} from "@/services/api.service"
import SelectGroup from "@/components/form/SelectGroup.vue"
import { taskStatuses } from "@/dictionaries/dictionary"
import PhoneSvg from "@/assets/svg-vue/header/phone_t.svg.vue"
import WhatsappTelegramIcon from "@/components/pages/patients/WhatsappTelegramIcon.vue"
import SmsSvg from "@/assets/svg-vue/general/sms_t.svg.vue"
import { copyObject } from "@/extensions/prototypes/prototypes"

export default {
  name: "TaskShowModal",
  data() {
    return {
      taskStatuses,
      selectedPhone: null
    }
  },
  components: {
    SmsSvg,
    WhatsappTelegramIcon,
    PhoneSvg,
    SelectGroup,
    GeneralCommentsBlock,
    CRMDatatableTDTaskType
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      loading: state => state.dom.loading,
      task: state => state.crm.task,
      rights: state => state.auth.rights,
    }),
    preUrl() {
      let link = JwtService.getPrintPrelink(this.clinic)
      return link+'/task/file/'
    },
    phone() {
      return formatPhone(this.task.phone || this.task.patient?.phone)
    },
    patient() {
      return this.task.patient
    }
  },
  methods: {
    formatDateTime,
    edit() {
      this.$bvModal.hide('task-show-modal')
      let newObject = JSON.parse(JSON.stringify(this.task))
      this.$store.commit('setModule', 'crm')
      this.$store.commit('setSelectedModel', newObject)
      this.$store.commit('setViewModel', 'task')
      this.$nextTick(() => {
        this.$store.commit('setShowModal', true)
      })
    },
    async changeStatus(status) {
      let res = await CalendarService.finishTask(this.task.id, status)
      if(res.data) this.$store.commit('incrementDataTableCounter')
    },
    async smsModal() {
      this.$store.commit('setPatient', this.patient)
      this.$bvModal.show('patient-sms-modal')
    },
    onHide() {
      const queryParams = { ...this.$route.query }
      if(queryParams.id) {
        delete queryParams.id
        this.$router.replace({ query: queryParams })
      }
    },
    async onShow() {
      const res = await EloquentService.show('task', this.task.id)
      this.$store.commit('setTask', copyObject(res.data))
    },
    async leadClicked() {
      if(!this.task.lead_id) return
      this.$bvModal.hide('task-show-modal')
      const leadFull = await EloquentService.show('lead', this.task.lead_id)
      this.$store.commit('setLead', copyObject(leadFull.data))
      this.$bvModal.show('lead-show-modal')
    }
  },
}
</script>
