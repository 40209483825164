import ru from './ru/ru'
import en from './en/en'
import uk from './uk/uk'
import uz from './uz/uz'

export default {
    ru: ru,
    en: en,
    uk: uk,
    uz: uz,
}
