<template>
  <b-modal id="kkm-card-pay-modal"
           size="lg"
           @hide="modalResolve(false)"
           hide-footer
           centered>
    <div class="alert alert-warning text-center">
      <div class="text-center mb-3 pt-3">
        <b-spinner></b-spinner>
      </div>
      {{ $t('waiting_for_payment_in_terminal') }}
    </div>
    <!--    <div class="text-right mt-5">-->
    <!--      <a @click="hideModal" class="btn btn-themed btn-themed-outline btn-themed-squared">-->
    <!--        {{ $t('cancel') }}-->
    <!--      </a>-->
    <!--      <a @click="success" class="btn btn-themed btn-themed-squared ml-3">-->
    <!--        {{ $t('payment_accepted') }}-->
    <!--      </a>-->
    <!--    </div>-->
  </b-modal>
</template>

<script setup>
import { getCurrentInstance } from 'vue'
import { watch } from 'vue'

const props = defineProps({
  modalResolve: {
    type: Function,
    default: () => {}
  },
  status: {
    type: [Number, null],
    default: null
  }
})

const instance = (getCurrentInstance())

watch(() => props.status, (newVal) => {
  if(newVal === null) return
  props.modalResolve(newVal === 0)
  hideModal()
})

const hideModal = () => {
  const bvModal = instance?.ctx?._bv__modal
  bvModal?.hide('kkm-card-pay-modal')
}
</script>
