export const agreementTemplateAdapter = ({
    title,
    text26,
    filename,
}) => ({
    title: title ? title : null,
    text26: text26 ? text26 : null,
    document_type: 'agreement',
    filename,
})


