<template>
  <div class="mt-3 report-materials-movement">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   initialOrder="date"
                   initialSort="desc"
                   :rows="rows"/>
  </div>
</template>

<script>
import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"
import moment from "moment"

export default {
  name: "ReportsMaterialsMovement",
  components: {
    DataTableHtml
  },
  props: {
    from: {},
    to: {},
    warehouses: {
      type: Array
    },
    materials: {
      type: Array
    },
    materialCategories: {
      type: Array
    },
    selectedBranches: {}
  },
  head() {
    return {
      title: this.$t('materials_movement_analytics'),
    }
  },
  data() {
    return {
      columns: [
        {
          id: 'name',
          field: 'name',
          label: this.$t('title'),
          sortable: true,
        },
        {
          id: 'barcode',
          field: 'barcode',
          label: this.$t('barcode'),
          sortable: true
        },
        {
          id: 'artikul',
          field: 'artikul',
          label: this.$t('artikul'),
          sortable: true
        },
        {
          id: 'beginning_stock',
          field: 'beginning_stock',
          label: this.$t('beginning_balance'),
          format_number: true,
          sortable: true,
        },
        {
          id: 'in',
          field: 'in',
          label: this.$t('addition'),
          format_number: true,
          sortable: true
        },
        {
          id: 'out',
          field: 'out',
          label: this.$t('expense'),
          format_number: true,
          sortable: true,
        },
        {
          id: 'current_stock',
          field: 'current_stock',
          label: this.$t('current_stock'),
          format_number: true,
          sortable: true,
        },
      ],
      rows: []
    }
  },
  methods: {
    loadReport() {
      ReportsService.loadReport('materials_movement_analytics', {
        from: this.from,
        to: this.to,
        branch_id: this.selectedBranches,
        warehouses: this.warehouses.map(a => a.id),
        materials: this.materials.map(a => a.id),
        materialCategories: this.materialCategories.map(a => a.id),
      }).then(res => {
        this.rows = res.data
      })
    },
    excel() {
      this.$refs.datatable.excel()
    },
    setDates(value) {
      const date = value ? moment(value) : moment()
      this.$nextTick(() => {
        this.$emit('updateFrom', date.clone().startOf('month').format('YYYY-MM-DD'))
        this.$emit('updateTo', date.clone().endOf('month').format('YYYY-MM-DD'))
      })
    }
  },
  mounted() {
    this.loadReport()
  },
  watch: {
    from(val) {
      this.setDates(val)
    },
    to(val) {
      this.setDates(val)
    },
  }
}
</script>
