const TOKEN_KEY = "calendar-settings"
import { ViewRange, ViewType } from "@/components/pages/calendar/calendar/const/CalendarConst"

export const getCalendarSettings = () => {
  const def = {
    curView: ViewType.DOCTOR,
    curRange: ViewRange.DAY,
    administratorsCurRange: ViewRange.DAY,
    assistantsCurRange: ViewRange.DAY,
    showDoctorColor: true,
    showServiceColor: true,
    showStatusColor: true,
    showOverflowScreen: true,
    showClientTags: true,
    showPlannedServices: false,
    fillDoctorColor: false,
    showRecordTagColor: false,
    hideDoctorsWithoutSchedule: false,
    hideChairsWithoutSchedule: false,
    calendarDays: 1,
  }
  return JSON.parse(window.localStorage.getItem(TOKEN_KEY) ?? JSON.stringify(def))
}

export const saveCalendarSettings = (settings) => {
  window.localStorage.setItem(TOKEN_KEY, JSON.stringify(settings))
}

export const saveCalendarSetting = (key, value) => {
  let settings = getCalendarSettings()
  settings[key] = value
  saveCalendarSettings(settings)
}

export const getSelectedDoctors = () => {
  const doctors = window.localStorage.getItem('selectedDoctors')
  return doctors ? JSON.parse(doctors) : []
}

export const getSelectedAdministrators = () => {
  const admins = window.localStorage.getItem('selectedAdministrators')
  return admins ? JSON.parse(admins) : []
}

export const getSelectedAssistants = () => {
  const assistants = window.localStorage.getItem('selectedAssistants')
  return assistants ? JSON.parse(assistants) : []
}

export const setSelectedDoctors = (doctors) => {
  window.localStorage.setItem('selectedDoctors', JSON.stringify(doctors))
}

export const setSelectedAdministrators = (admins) => {
  window.localStorage.setItem('selectedAdministrators', JSON.stringify(admins))
}

export const setSelectedAssistants = (assistants) => {
  window.localStorage.setItem('selectedAssistants', JSON.stringify(assistants))
}



export default {
  getCalendarSettings,
  saveCalendarSetting,
  saveCalendarSettings,
  setSelectedDoctors,
  setSelectedAdministrators,
  setSelectedAssistants,
  getSelectedDoctors,
  getSelectedAdministrators,
  getSelectedAssistants
}
