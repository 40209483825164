export const contractTemplateAdapter =
    ({
         title,
         text26,
         filename
     }) => ({
        title: title ? title : null,
        text26: text26 ? text26 : null,
        document_type: 'contract',
        filename: ['null', '1', undefined].includes(filename) ? null : filename
    })
