<template>
  <div class="mt-4 patient-comments">
    <div class="nowrap overflow-scroll">
      <CheckboxGroup v-for="c in checks"
                     :value="checked"
                     :checkboxvalue="c.id"
                     class="d-inline-block mr-3"
                     :key="`check-${c.id}`"
                     :fid="`check-${c.id}`"
                     @input="(...args) => checkboxInput(c.id, ...args)"
                     :label="$t(c.title)"/>
    </div>
    <div>
      <CheckboxGroup :label="$t('show_images')"
                     class="sm"
                     fid="show_images"
                     v-model="showImages"/>
    </div>
    <b-row>
      <b-col v-if="patient && patient.id" lg="12">
        <div>
          <AutosizeTextArea v-model="comment"
                            labelclass="black"
                            :label="$t('leave_comment')"/>
          <div class="text-right" v-if="comment">
            <label class="mr-3 text-muted pointer" for="files" v-if="!files.length">
              <ClipSvg class="mr-2"/>{{ $t('add_attachment') }}
            </label>
            <div v-if="files.length" class="d-inline-block mr-3">
              <div v-for="(f, find) in files" :key="`k-${find}`">
                {{ f.name }}
                <CloseSvg class="svg-gray close-svg svg-size-sm ml-2 pointer" @click="deleteFile(find)"/>
              </div>
            </div>
            <input type="file"
                   accept=".xls, .jpg, .xlsx, .doc, .docx, .pdf, .txt, .jpeg, .png, .gif"
                   class="d-none"
                   :key="`key-${keyCounter}`"
                   id="files"
                   @change="processFile($event)"/>
            <button class="btn-themed btn-themed-squared"
                    @click="saveComment"
                    :disabled="loading || !comment.length">
              {{ $t('send') }}
            </button>
          </div>

        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12" class="mb-5">

        <div v-if="!comments || !comments.length && loaded">
          {{ $t('no_data_available') }}
        </div>
        <PatientCommentTeaser v-for="c in comments"
                              :key="`comment-${c.id}`"
                              :comment="c"
                              :show-images="showImages"
                              @getComments="getComments(true)"
                              class="mb-3">
        </PatientCommentTeaser>
        <div v-if="hasMore"
             class="mb-3">
          <button :disabled="loading"
                  class="btn btn-themed btn-themed-outline btn-themed-squared"
                  @click="loadMore">
            {{ $t('load_more') }}
          </button>
        </div>
      </b-col>
    </b-row>
    <LeadShowModal/>
  </div>
</template>

<script>

import { mapState } from "vuex"
import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import { EloquentService } from "@/services/api.service"
import PatientCommentTeaser from "@/components/parts/patients/PatientCommentTeaser"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import ClipSvg from '@/assets/svg-vue/form/clip.svg'
import CloseSvg from '@/assets/svg-vue/general/close.svg'
import LeadShowModal from "@/components/parts/crm/LeadShowModal.vue"

export default {
  name: "PatientComments",
  components: {
    LeadShowModal,
    CheckboxGroup,
    PatientCommentTeaser,
    AutosizeTextArea,
    ClipSvg,
    CloseSvg
  },
  head() {
    return {
      title: this.$t("live_feed"),
    }
  },
  data() {
    return {
      checks: [
        { id: 'all', title: 'all' },
        { id: 'manual', title: 'comments' },
        { id: 'record', title: 'visits' },
        { id: 'notification', title: 'notifications' },
        { id: 'payment', title: 'payments' },
        { id: 'advance', title: 'deposit' },
        { id: 'treatment_plan', title: 'plan_treatments' },
        { id: 'task', title: 'tasks' },
        { id: 'lead', title: 'leads' },
        { id: 'bonus', title: 'bonuses' },
        { id: 'call', title: 'calls' },
        { id: 'visit_timing', title: 'visit_time' },
        { id: 'patient', title: 'patient' },
      ],
      checked: [],
      comment: '',
      comments: [],
      files: [],
      loaded: false,
      paginator: null,
      page: 1,
      keyCounter: 1,
      dataTableWatcher: true,
      showImages: true
    }
  },
  computed: {
    ...mapState({
      patient: state => state.patient.patient,
      loading: state => state.dom.loading,
      counter: state => state.datatable.dataTableCounter,
    }),
    hasMore() {
      if(!this.paginator) return false
      return this.page < this.paginator.last_page
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  mounted() {
    const savedFilters = window.localStorage.getItem('patient_comments_filters')
    this.checked = savedFilters ? JSON.parse(savedFilters) : this.checks.map(x => x.id)
  },
  methods: {
    saveComment() {
      if(!this.comment) return
      if(!this.patient || !this.patient.id) return
      let formData = new FormData()
      formData = this.appendFormdata(formData, {
        user_id: this.patient.id,
        text: this.comment
      })
      if(this.files.length) {
        this.appendFormdata(formData, { files: this.files })
      }
      EloquentService.create('patient_comment', formData).then(() => {
        this.comment = ''
        this.files = []
        this.keyCounter++
        this.getComments(true)
      })
    },
    getComments(flush = false) {
      if(this.patient && this.patient.id) {
        if(flush) this.page = 1
        EloquentService.dataTable('patient_comment', this.page, {
          pagination: 10,
          order: 'created_at',
          sort: 'desc',
          condition: { user_id: this.patient.id, comment_type: this.checked },
        }).then(res => {
          if(flush) this.comments = []
          // this.comments = res.data.result.data.reverse().concat(this.comments)
          this.comments = this.comments.concat(res.data.result.data)
          this.paginator = res.data.result
          this.loaded = true
          this.dataTableWatcher = false
          this.$nextTick(() => {
            this.$store.commit('incrementDataTableCounter')
            setTimeout(() => {
              this.dataTableWatcher = true
            }, 100)
          })
        })
      }
    },
    loadMore() {
      this.page++
      this.getComments()
    },
    checkboxInput(clicked, all) {
      if(clicked === 'all' && all.includes('all')) {
        this.checked = this.copyObject(this.checks.map(x => x.id))
        this.saveFilters()
        return
      }
      if(clicked === 'all' && !all.includes('all')) {
        this.checked = []
        this.saveFilters()
        return
      }
      this.checked = all.filter(x => x !== 'all')
      if(this.checked.length === (this.checks.length - 1)) {
        this.checked.push('all')
      }
      this.saveFilters()
    },
    saveFilters() {
      window.localStorage.setItem('patient_comments_filters', JSON.stringify(this.checked))
    },
    processFile(event) {
      let file = event.target.files[0]
      if(!file) return
      if(file.size > (this.sizelimit * 1024 * 1024)) {
        this.$noty.error(this.$t('file_size_is_too_big'))
        return
      }
      this.files.push(file)
    },
    deleteFile(index) {
      this.files.splice(index, 1)
      this.keyCounter++
    },
    handleScroll() {
      if(!this.loaded || this.loading || !this.hasMore) return
      let bottomOfWindow = (Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight) > (document.documentElement.offsetHeight - 40)
      if (bottomOfWindow) {
        this.loadMore()
      }
    }
  },
  watch: {
    checked: {
      handler() {
        this.getComments(true)
      },
    },
    counter() {
      if(this.dataTableWatcher) this.getComments(true)
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style scoped lang="scss">
.img-preview {
  width: 220px;
  height: 180px;
  object-fit: cover;
  margin-right: 15px;
}
.patient-comments {
  max-width: 1200px;
  .comment-block {
    white-space: break-spaces;
    word-break: break-word;
  }
}
</style>
