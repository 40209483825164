<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   initialOrder="date"
                   initialSort="asc"
                   :rows="rows"/>
  </div>
</template>

<script>

import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"

export default {
  name: "ReportStatVisits",
  components: {
    DataTableHtml
  },
  props: {
    from: {},
    to: {},
    doctors: {
      type: Array
    },
    chairs: {
      type: Array
    },
    selectedBranches: {},
    oneStaff: {}
  },
  head() {
    return {
      title: this.$t('visit_statistics'),
    }
  },
  data() {
    return {
      columns: [
        {
          id: 'date',
          field: 'date',
          label: this.$t('date'),
          sortable: true,
          format_date: true
        },
        {
          id: 'total_patients',
          field: 'total_patients',
          label: this.$t('total_patients'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'total_visits',
          field: 'total_visits',
          label: this.$t('visits'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'primary',
          field: 'primary',
          label: this.$t('primary_among_patients'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'permanent',
          field: 'permanent',
          label: this.$t('permanent_among_patients'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'came',
          field: 'came',
          label: this.$t('came_among_patients'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'did_not_come',
          field: 'did_not_come',
          label: this.$t('did_not_come_among_patients'),
          sortable: true,
          format_number: true,
          totals: true
        },
        // {
        //   id: 'patient_name',
        //   field: 'patient_name',
        //   label: this.$t('patient'),
        //   search_select: true,
        //   sortable: true
        // },
        // {
        //   id: 'title',
        //   field: 'title',
        //   label: this.$t('diagnosis'),
        //   search_select: true,
        //   sortable: true
        // },
        // {
        //   id: 'doctor_name',
        //   field: 'doctor_name',
        //   label: this.$t('doctor'),
        //   search_select: true,
        //   sortable: true
        // },
      ],
      rows: []
    }
  },
  methods: {
    loadReport() {
      ReportsService.loadReport('visit_statistics', {
        from: this.from,
        to: this.to,
        doctors: this.doctors.map(a => a.id),
        chairs: this.chairs.map(a => a.id),
        branch_id: this.selectedBranches,
        created_by: this.oneStaff?.id || null
      }).then(res => {
        this.rows = res.data
      })
    },
    excel() {
      this.$refs.datatable.excel()
    }
  },
  mounted() {
    this.loadReport()
  }
}
</script>

<style scoped>

</style>
