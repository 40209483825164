<template>
  <div class="dc-menu__item d-flex align-items-center pointer">
    <div class="dc-menu__icon">
      <MenuCancel class="svg-red" v-if="type === 'CANCEL'" />
      <MenuDelete v-if="type === 'DELETE'" />
      <MenuConfirm v-if="type === 'CONFIRM'" />
      <MenuCheck v-if="type === 'CAME'" />
      <MenuEdit v-if="type === 'EDIT'" />
      <MenuView v-if="type === 'VIEW'" />
      <MenuEmail v-if="type === 'EMAIL'" />
      <MenuPay v-if="type === 'PAY'" />
      <MenuPatient v-if="type === 'PATIENT'" />
      <MenuPhone v-if="type === 'PHONE'" />
      <MenuSMS v-if="type === 'SMS'" />
      <MoveSvg v-if="type === 'MOVE'" />
      <CopySvg v-if="type === 'COPY'" />
      <MenuNotComeSvg v-if="type === 'DID_NOT_COME'" class="svg-red"/>
      <slot/>
    </div>
    <div class="dc-menu__title"
         :class="{'text-danger': ['DELETE','CANCEL','DID_NOT_COME'].includes(type) }">
      {{ title }}
    </div>
  </div>
</template>

<script>

import MenuDelete from '@/assets/svg-vue/calendar/menu_cancel.svg'
import MenuCancel from '@/assets/svg-vue/calendar/menu_delete.svg'
import MenuConfirm from '@/assets/svg-vue/calendar/menu_confirm.svg'
import MenuEdit from '@/assets/svg-vue/calendar/menu_edit.svg'
import MenuView from '@/assets/svg-vue/calendar/menu_view.svg'
import MenuEmail from '@/assets/svg-vue/calendar/menu_email.svg'
import MenuPay from '@/assets/svg-vue/calendar/menu_pay.svg'
import MenuPatient from '@/assets/svg-vue/calendar/menu_patient.svg'
import MenuPhone from '@/assets/svg-vue/calendar/menu_phone.svg'
import MenuSMS from '@/assets/svg-vue/calendar/menu_sms.svg'
import MenuCheck from '@/assets/svg-vue/calendar/menu_check.svg'
import MoveSvg from '@/assets/svg-vue/general/move.svg'
import CopySvg from '@/assets/svg-vue/patients/copy3.svg'
import MenuNotComeSvg from '@/assets/svg-vue/calendar/menu_not_come.svg'

export default {
  name: "DMenuItem",
  components: {
    MenuCancel,
    MenuDelete,
    MenuConfirm,
    MenuEmail,
    MenuEdit,
    MenuView,
    MenuPatient,
    MenuPay,
    MenuPhone,
    MenuSMS,
    MenuCheck,
    MoveSvg,
    CopySvg,
    MenuNotComeSvg
  },
  props: {
    type: String,
    title: String,
    isCancel: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.dc-menu__item {
  padding: 6px 45px 6px 15px;
  background-color: white;
  color: #5D6D7C;
  &.symmetric {
    padding-right: 15px;
  }
}
.dc-menu__item:hover {
  background-color: #F3F3F3;
}
.dc-menu__icon {
  display: flex;
  width: 28px;
}
</style>
