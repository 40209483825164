<template>
  <td class="action-buttons-td no-print" v-if="(options.actions && options.actions.some(r => ['edit','delete'].includes(r))) || options.actionscomponent">
    <a class="d-md-none d-inline-block px-2 action-dots" @click="dotsPressed"><DotsSvg/></a>
    <div class="action-buttons-div" :class="{ expanded: mob_expanded }" v-click-outside="clickedOutside">
      <component v-if="options.actionscomponent" :is="actioncomponents[options.actionscomponent]" :object="object"/>
      <a class="data-table-action"
         :title="$t('edit')"
         v-b-tooltip.hover
         @click="editRow"
         v-if="canEdit">
        <EditSvg/><span class="action-button-mob-title">{{ $t('edit') }}</span>
      </a>
      <a class="data-table-action"
         :title="$t('delete')"
         v-b-tooltip.hover
         @click="deleteRow"
         v-if="canDelete">
        <DeleteSvg/><span class="action-button-mob-title">{{ $t('delete') }}</span>
      </a>
    </div>
  </td>
</template>

<script>

import EditSvg from '@/assets/svg-vue/general/edit.svg'
import DeleteSvg from '@/assets/svg-vue/general/delete.svg'
import DotsSvg from '@/assets/svg-vue/header/vertical_dots.svg'
import { mapState } from "vuex"
import { templateTypesToModelNames } from "@/dictionaries/dictionary"

export default {
  name: "ActionButtons",
  components: {
    EditSvg,
    DeleteSvg,
    DotsSvg
  },
  props: {
    options: {
      type: Object
    },
    index: {
      required: true
    },
    module: {
      type: String
    },
    object: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      mob_expanded: false,
      justOpened: false
    }
  },
  methods: {
    editRow() {
      let newObject = JSON.parse(JSON.stringify(this.object))
      if(this.options.editAdditional) {
        newObject = { ...this.options.editAdditional, ...newObject }
      }
      this.$store.commit('setModule', this.module)
      this.$store.commit('setSelectedModel', newObject)
      this.$store.commit('setShowModal', true)
    },
    deleteRow() {
      let mes = 'are_you_sure_you_want_to_delete'
      if(this.options.deletemessage) mes = this.options.deletemessage
      if(confirm(this.$t(mes))) {
        const successMessage = this.$t('success_message')
        let model = null
        if(this.options.model === 'general_template') {
          model = templateTypesToModelNames[this.object.template_type]
        }
        this.$store.dispatch('deleteModel', {
          id: this.object[this.options.primaryKey],
          model: model,
          superadmin: this.superadmin
        }).then(() => {
          this.$noty.info(successMessage)
        })
      }
    },
    dotsPressed() {
      this.justOpened = true
      this.mob_expanded = !this.mob_expanded
      setTimeout(() => {
        this.justOpened = false
      },20)
    },
    clickedOutside() {
      if(!this.justOpened) {
        this.mob_expanded = false
      }
    }
  },
  computed: {
    actioncomponents () {
      const components = require.context(`@/components/parts/datatable/actionbuttons/`, true, /\.vue$/i)
      let arr = {}
      components.keys().map(x => {
        arr[x.split('/').pop().split('.')[0]] = components(x).default
      })
      return arr
    },
    ...mapState({
      rights: state => state.auth.rights,
      superadmin: state => state.auth.superadmin,
    }),
    canDelete() {
      if(!this.options.actions.includes('delete')) {
        return false
      }
      if(this.options.deleteCondition && !this.options.deleteCondition(this.object)) {
        return false
      }
      if(this.options.model === 'record' && this.object.status == 2) return false
      if(this.options.rights && this.options.rights.delete && !this.rights.includes(this.options.rights.delete)) {
        return false
      }
      return true
    },
    canEdit() {
      if(!this.options.actions.includes('edit')) {
        return false
      }
      if(this.options.editCondition && !this.options.editCondition(this.object)) {
        return false
      }
      if(this.options.rights && this.options.rights.edit && !this.rights.includes(this.options.rights.edit)) {
        return false
      }
      return true
    }
  },
}
</script>

<style lang="scss">

</style>
