<template>
  <span v-if="field" :class="{ negative: object[field.id] < 0 }">
    <template v-if="field.form && field.form.type === 'select' && field.form.options">
      {{ field.form.translate === false ? selectOption : $t(selectOption) }}
    </template>
    <span v-else-if="field.format_html" v-html="string"></span>
    <template v-else-if="field.format_number">{{ formatNumber(string, true) }}</template>
    <template v-else-if="field.format_currency">{{ formatCurrency(string) }}</template>
    <template v-else-if="field.format_percent">{{ string ? formatNumber(string)+'%' : '-' }}</template>
    <template v-else-if="field.format_email"><a :href="`mailto:${object[field.id]}`">{{ string }}</a></template>
    <template v-else-if="field.format_phone"><a class="td-phone" :href="`tel:${object[field.id]}`">{{ formatPhone(string) }}</a></template>
    <template v-else-if="field.format_phonecall"><OutgoingCallElement :phone="object[field.id]"/></template>
    <template v-else-if="field.format_date">{{ formatDate(string) }}</template>
    <template v-else-if="field.format_datetime">{{ formatDate(string, 'DD.MM.YYYY, HH:mm') }}</template>
    <template v-else-if="field.format_filesize">{{ formatFileSize(string) }}</template>
    <template v-else-if="field.format_translate">{{ string && $te(string) ? $t(string) : string }}</template>
    <template v-else-if="field.format_image"><a :href="string" target="_blank"><img class="admin-table-img" :src="string" alt=""/></a></template>
    <template v-else>{{ string }}</template>
  </span>
</template>

<script>

import OutgoingCallElement from "@/components/parts/patients/OutgoingCallElement.vue"
import {
  formatPhone,
  formatNumber,
  formatCurrency,
  formatDate,
  formatFileSize,
  nestedAttribute
} from "@/extensions/filters/filters"

export default {
  name: "TDComponent",
  components: {
    OutgoingCallElement
  },
  props: {
    field: {
      type: Object
    },
    object: {
      type: Object
    },
  },
  computed: {
    string() {
      if(this.field.table_show) {
        let show = this.field.table_show
        if(!Array.isArray(this.field.table_show)) show = [show]
        let str = ''
        show.forEach((item, index) => {
          if(index > 0) str += ', '
          str += this.nestedAttribute(this.object, item)
        })
        return str
      }
      return this.object[this.field.id]
    },
    selectOption() {
      if(this.field.form && this.field.form.type === 'select' && this.field.form.options) {
        if(!this.object[this.field.id]) return ''
        return this.field.form.options[this.object[this.field.id]]?.title || this.object[this.field.id]
      }
      return ''
    }
  },
  methods: {
    formatPhone,
    formatNumber,
    formatCurrency,
    formatDate,
    formatFileSize,
    nestedAttribute
  }
}
</script>
