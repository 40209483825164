<template>
    <div class="container pt-5">
<!--      <TestTabapay/>-->
<!--      <div>yandex: {{ yandex }}ms</div>-->
<!--      <div>hetzner: {{ hetzner }}ms</div>-->
<!--      <button @click="refresh">Refresh</button>-->
<!--      <CryptoProSignWidget v-if="cryptoLib"-->
<!--                           data-to-sign="cda"-->
<!--                           @signed="signed"/>-->
<!--      <CryptoProBrowserSignWidget v-else-->
<!--                                  data-to-sign="cda"-->
<!--                                  @signed="signed"/>-->
<!--      <div class="pt-5 text-wrap">{{ signature }}</div>-->
<!--      <a @click="kkmTest">Test me, baby, one more time!</a>-->
<!--      <a @click="awaitTest">await test</a>-->
      <b-modal id="fff"
               size="lg"
               :title="$t('calendar_settings')"
               @hide="modalResolve('error')"
               centered>
        <a @click="modalResolve('success')">resolve success</a>
        <a @click="modalResolve('error')">resolve error</a>
        <a @click="modalReject">resolve error</a>
      </b-modal>
      <div>
        <code>{{ result }}</code>
      </div>
    </div>
</template>

<script>
// import { TestService } from "@/services/api.service"
import { mapState } from "vuex"
import { KKMService } from "@/services/kkm.service"
// import TestTabapay from "@/components/pages/settings/TestTabapay.vue"
// import CryptoProSignWidget from "@/components/parts/egisz/CryptoProSignWidget.vue"
// import CryptoProBrowserSignWidget from "@/components/parts/egisz/CryptoProBrowserSignWidget.vue"

export default {
  name: "SettingsTest",
  components: {
    // TestTabapay
    // CryptoProSignWidget,
    // CryptoProBrowserSignWidget
  },
  data() {
    return {
      yandex: null,
      hetzner: null,
      cryptoLib: true,
      signature: null,
      result: '',
      modalResolve: null,
      modalReject: null
    }
  },
  computed: {
    ...mapState({
      collapseDictionary: state => state.dom.spaceduckCollapse,
    }),
  },
  methods: {
    async kkmTest() {
      // this.result = await KKMExecuteCommand({ Command: 'GetDataKKT' })
      // this.result = await KKMService.openShift()
      this.result = await KKMService.printXReport()
      // console.log(res)
    },
    async awaitTest() {
      const res = await this.modalTest()
      console.log(res)
      console.log('asdadf')
    },
    async modalTest() {
      return new Promise((resolve, reject) => {
        this.$bvModal.show('fff')
        this.modalReject = reject
        this.modalResolve = resolve
      })
    },
    // refresh() {
    //   this.hetzner = null
    //   this.yandex = null
    //   TestService.hetznerTest().then(res => {
    //     this.hetzner = res.data
    //   }).catch(() => {})
    //   TestService.yandexTest().then(res => {
    //     this.yandex = res.data
    //   }).catch(() => {})
    // },
    signed(val) {
      console.log(val)
      this.signature = val
    }
  },
  mounted() {
    // this.refresh()
    // fetch('https://api.dentist-plus.com/test.php?t='+Date.now()).then()
  }

}
</script>
